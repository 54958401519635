import React from "react";
import {Observer} from 'mobx-react';
import {Context} from "../utils/Context";
import {
    Link
} from "react-router-dom";

export default function ({children, ...rest}) {
    return <Context.Consumer>
        {(store) => (
            <Observer>
                {() => (
                    <div>
                        <header>
                            <div className="wrapper">
                                <a href="#" className="logo">Центр одаренных детей</a>
                                <div className="profile">
                                    <div onClick={() => {
                                        store.headerPopup = !store.headerPopup
                                    }} className="profile-link">
                                        <div className="profile-avatar" style={{
                                            backgroundImage: (store.user.photo ? 'url(' + (store.user.photo.indexOf('data:') === 0 ? '' : 'data:image/jpeg;base64,') + store.user.photo + ')' : null),
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center'
                                        }}></div>
                                        {store.user.name}</div>
                                    {store.headerPopup ? <div className="profile-popup">
                                        <Link to="/profile" onClick={() => {
                                            store.headerPopup = false;
                                        }}>Редактировать личные данные</Link>
                                        <Link to="/logout" onClick={() => {
                                            store.headerPopup = false;
                                            store.logout()
                                        }}>Выйти из личного кабинета</Link>
                                    </div> : null}
                                </div>
                                <button onClick={()=>{
                                    store.mobileMenuShown = !store.mobileMenuShown;
                                }} className={"desktop-hidden mobile-menu-btn "+(store.mobileMenuShown?" cross ":" ")+(!store.mobileMenuShown && store.newMessagesCount?"menu-badge":"")}></button>
                            </div>
                        </header>
                        {store.mobileMenuShown ? <section className="desktop-hidden sidebar mobile-menu">
                                <div className="sidebar-menu">
                                    <Link to="/" onClick={()=>{
                                    store.mobileMenuShown = false;
                                }} className="sidebar-main">Главная</Link>
                                    <Link to="/messages" onClick={()=>{
                                    store.mobileMenuShown = false;
                                }}  className="sidebar-messages">Мои
                                        сообщения {store.newMessagesCount ?
                                            <div className="badge">{store.newMessagesCount}</div> : null}</Link>
                                    <Link to="/events" onClick={()=>{
                                    store.mobileMenuShown = false;
                                }}  className="sidebar-events">Мероприятия</Link>
                                    <Link to="/portfolio" onClick={()=>{
                                    store.mobileMenuShown = false;
                                }}  className="sidebar-portfolio">Портфолио</Link>
                                    {store.role == 'student' ?
                                        <Link to="/tests" onClick={()=>{
                                    store.mobileMenuShown = false;
                                }}  className="sidebar-tests">Тестирование</Link> : null}
                                    <Link to="/docs" onClick={()=>{
                                    store.mobileMenuShown = false;
                                }}  className="sidebar-docs">Документы</Link>
                                </div>
                                <div className="spacer"></div>
                            <Link to="/profile" className="profile-link" onClick={()=>{
                                    store.mobileMenuShown = false;
                                }}>
                                Редактировать личные данные</Link>

                            <Link to="/logout" className="profile-link" onClick={() => {
                                store.mobileMenuShown = false;
                                store.logout()
                            }}>Выйти из личного кабинета</Link>

                                <a href="https://tau18.ru" className="sidebar-back">Вернуться на сайт ТАУ</a>
                            </section> : null}
                        <div className="content" onClick={() => {
                            store.headerPopup = false
                        }}>
                            {store.showSidebar ? <section className="sidebar mobile-hidden">
                                <div className="sidebar-menu">
                                    <Link to="/" className="sidebar-main">Главная</Link>
                                    <Link to="/messages" className="sidebar-messages">Мои
                                        сообщения {store.newMessagesCount ?
                                            <div className="badge">{store.newMessagesCount}</div> : null}</Link>
                                    <Link to="/events" className="sidebar-events">Мероприятия</Link>
                                    <Link to="/portfolio" className="sidebar-portfolio">Портфолио</Link>
                                    {store.role == 'student' ?
                                        <Link to="/tests" className="sidebar-tests">Тестирование</Link> : null}
                                    <Link to="/docs" className="sidebar-docs">Документы</Link>
                                </div>
                                <div className="spacer"></div>
                                <a href="https://tau18.ru" className="sidebar-back">Вернуться на сайт ТАУ</a>
                            </section> : null}
                            <section className={store.showSidebar ? "main padding" : "main no-padding"}>
                                {children}
                            </section>
                        </div>
                        {store.confirmation ?
                            <div className="confirmation">
                                <div>{store.confirmation}</div>
                            </div>
                            : null}
                        {store.errorMessage ?
                            <div className="error-message">
                                <div>{typeof(store.errorMessage == 'string') ? store.errorMessage : store.renderError(store.errorMessage)}</div>
                            </div>
                            : null}

                        {store.loading ?
                            <div style={{
                                position: 'fixed',
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                background: 'rgba(255,255,255,0.5)',
                                zIndex: 10000,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <div className="lds-spinner">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                            : null}
                    </div>
                )}
            </Observer>
        )}
    </Context.Consumer>;
}