import React, {useState, useEffect} from 'react';
import {Observer} from 'mobx-react';
import {Context} from "../utils/Context";
import {EditorState, ContentState} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import {
    Link
} from "react-router-dom";

import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import {FileInput, PreviewImg} from "../utils/FileUpload";
import {Editor} from 'react-draft-wysiwyg';
import dayjs from 'dayjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import InputMask from "react-input-mask";

const axios = require('axios');

export default function ({children, ...rest}) {
    return <Context.Consumer>
        {(store) => (
            <Observer>
                {() => {
                    if (store.role == 'student') {
                        window.location.href = "/events";
                        return null;
                    }
                    let parts = window.location.pathname.split('/');
                    let eventId = parts[parts.length - 1];
                    let event = null;
                    if (eventId != 'new') {
                        store.events.forEach((el) => {
                            if (eventId == el.id) {
                                event = el;
                            }
                        });
                    } else {
                        store.newEventId = 0;
                    }
                    if (event && !store.newEventId && store.newEventId != eventId) {
                        const blocksFromHtml = htmlToDraft(event.description);
                        const {contentBlocks, entityMap} = blocksFromHtml;
                        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                        store.newEventId = event.id;
                        store.newEventName = event.short_name;
                        store.newEventPhotoSrc = event.skinSrc;
                        store.rewardImgSrc = event.rewardImg;
                        store.newEventFiles = event.files;
                        store.newEventDate = event.start.substr(0, 10);
                        store.newEventStart = event.regStart.substr(0, 10);
                        store.newEventEnd = event.regEnd.substr(0, 10);
                        store.newEventDirection = event.direction_id;
                        store.editorState = EditorState.createWithContent(contentState);
                        store.newEventType = event.type_id;
                        store.newEventLevel = event.level_id;
                        store.newEventParticipants = event.max_participants;
                        store.newEventMinAge = event.ageMin;
                        store.newEventMaxAge = event.ageMax;
                        store.newEventRegistryFlag = event.registry;
                        store.newEventReqAttachmentFlag = event.req_attach;
                        store.newEventOrgName = event.organization;
                        store.newEventOrgAddress = event.org_address;
                        store.newEventOrgEmail = event.org_email;
                        store.newEventOrgPhone = event.org_phone;
                        store.newEventAddressObj = {
                            address: event.location,
                            lat: event.lat,
                            lng: event.lng
                        };
                    }
                    return (
                        <div className="mobile-padding">
                            <h1>{store.newEventId ? 'Редактировать мероприятие' : 'Создать мероприятие'}</h1>


                            {(store.newEventError || store.serverNewEventError || store.serverError) ?
                                <ul className="row errors-block">
                                    {store.newEventError ? <li>{store.newEventError}</li> : null}
                                    {store.serverNewEventError ? store.renderError(store.serverNewEventError) : null}
                                    {store.serverError ? store.serverError : null}
                                </ul> : null}

                            <h2>О мероприятии</h2>
                            <div className="input-row">
                                <label>Название мероприятия (не более 140 символов):</label>
                                <input type="text" className="large"
                                       value={store.newEventName ? store.newEventName : ''}
                                       onChange={(e) => {
                                           if (e.target.value.length <= 140) {
                                               store.newEventName = e.target.value
                                           }
                                       }} placeholder="Название мероприятия"/>
                            </div>
                            <div className="input-row">
                                <label>Направление мероприятия:</label>
                                <div className="row break">
                                    {store.directions.map((el) => (
                                        <button
                                            style={{marginRight: '10px'}}
                                            onClick={() => {
                                                store.newEventDirection = el.id
                                            }}
                                            className={"btn light small " + (store.newEventDirection == el.id ? '' : 'border')}
                                            key={'direction-' + el.id}
                                        >{el.name}</button>
                                    ))}
                                </div>
                            </div>

                            <div className="event-edit-block">
                                <div className="input-column" style={{paddingRight: '10px'}}>
                                    <label>Тип мероприятия:</label>
                                    <select className="full"
                                            value={store.newEventType}
                                            onChange={(e) => {
                                                store.newEventType = e.target.value
                                            }}>
                                        <option value="0" key={'type-0'}>Не указано</option>
                                        {store.types.filter((el) => {
                                            return !el.del
                                        }).map((el) => (
                                            <option value={el.id} key={'type-' + el.id}>{el.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="input-column" style={{paddingRight: '10px'}}>
                                    <label>Уровень мероприятия:</label>
                                    <select className="full"
                                            value={store.newEventLevel}
                                            onChange={(e) => {
                                                store.newEventLevel = e.target.value
                                            }}>
                                        <option value="0" key={'level-0'}>Не указан</option>
                                        {store.levels.map((el) => (
                                            <option value={el.id} key={'level-' + el.id}>{el.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>


                            <div className="input-row">
                                <label>Фотография анонса:</label>
                                {!store.newEventPhoto && store.newEventPhotoSrc ?
                                    <a href={store.newEventPhotoSrc} target="_blank"><img
                                        src={store.newEventPhotoSrc + "?size=s"} style={{
                                        height: 'auto',
                                        width: '200px'
                                    }}/></a> : null}
                                {store.newEventPhoto ? <PreviewImg value={store.newEventPhoto}/> : null}
                                <div className="row">
                                    <FileInput value={store.newEventPhoto} onChange={(files) => {
                                        if (files.length) {
                                            store.getBase64(files[0], (value, filename) => {
                                                store.newEventPhoto = {
                                                    content: value.split('base64,')[1],
                                                    name: filename
                                                }
                                            })
                                        }
                                    }}/>
                                    <small className="btn-notes">jpg, png, максимальный размер 400kb.</small>
                                </div>
                            </div>


                            <div className="row">
                                <div className="input-row">
                                    <label>Описание мероприятия:</label>
                                    <Editor
                                        toolbar={{
                                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'remove', 'history']
                                        }}
                                        editorState={store.editorState}
                                        wrapperClassName="editor-block"
                                        editorClassName="editor-content"
                                        localization={{
                                            locale: 'ru',
                                        }}
                                        onEditorStateChange={(editorState) => {
                                            store.editorState = editorState
                                        }}
                                    />

                                </div>
                            </div>

                            <div className="event-edit-block">
                                <div className="input-column" style={{paddingRight: '10px'}}>
                                    <label>Дата проведения:</label>
                                    <input type="date" className="date"
                                           value={store.newEventDate ? store.newEventDate : ''}
                                           onChange={(e) => {
                                               store.newEventDate = e.target.value
                                           }} placeholder="ДД.ММ.ГГГГ"/>
                                </div>
                                <div className="input-column" style={{paddingRight: '10px'}}>
                                    <label>Начало регистрации:</label>
                                    <input type="date" className="date"
                                           value={store.newEventStart ? store.newEventStart : ''}
                                           onChange={(e) => {
                                               store.newEventStart = e.target.value
                                           }} placeholder="ДД.ММ.ГГГГ"/>
                                </div>
                                <div className="input-column" style={{paddingRight: '10px'}}>
                                    <label>Окончание регистрации:</label>
                                    <input type="date" className="date"
                                           value={store.newEventEnd ? store.newEventEnd : ''}
                                           onChange={(e) => {
                                               store.newEventEnd = e.target.value
                                           }} placeholder="ДД.ММ.ГГГГ"/>
                                </div>
                            </div>

                            <div className="row">
                                <div className="input-row">
                                    <label>Адрес:</label>
                                    <AsyncSelect
                                        className="select-large geocode"
                                        placeholder="Укажите адрес"
                                        cacheOptions
                                        inputValue={store.newEventAddress ? store.newEventAddress : ''}
                                        onInputChange={(inputValue, action) => {
                                            if (action.action !== "input-blur" && action.action !== "menu-close") {
                                                store.newEventAddress = inputValue
                                            }
                                        }}
                                        loadOptions={(inputValue, callback) => {
                                            axios.post(store.host + '/suggest.php', null, {
                                                params: {
                                                    'q': inputValue
                                                }
                                            }).then(function (data) {
                                                let result = [];
                                                data = data.data;
                                                if (data.suggestions && data.suggestions.length) {
                                                    data.suggestions.forEach((el) => {
                                                        result.push({
                                                            label: el.value,
                                                            value: el.data
                                                        })
                                                    })
                                                }
                                                callback(result);
                                            });
                                        }}
                                        noOptionsMessage={() => "Нет записей"}
                                        defaultOptions
                                        onChange={(el) => {
                                            store.newEventAddressObj.address = el.label;
                                            store.newEventAddressObj.lat = el.value.geo_lat;
                                            store.newEventAddressObj.lng = el.value.geo_lon;
                                        }}
                                        value={{label: store.newEventAddressObj.address, value: {}}}
                                    />
                                </div>
                            </div>
                            <div className="input-row">
                                <label>Перечень возможных наград:</label>
                                <input type="text" className="large"
                                       value={store.newEventAwards ? store.newEventAwards : ''}
                                       onChange={(e) => {
                                           store.newEventAwards = e.target.value
                                       }} placeholder="Перечень возможных наград"/>
                            </div>

                            <div className="input-row">
                                <label>Шаблон награды (грамоты):</label>
                                {!store.rewardImg && store.rewardImgSrc ?
                                    <a href={store.rewardImgSrc} target="_blank"><img
                                        src={store.rewardImgSrc + "?size=s"} style={{
                                        height: 'auto',
                                        width: '200px'
                                    }}/></a> : null}
                                {store.rewardImg ? <PreviewImg value={store.rewardImg}/> : null}
                                <div className="row">
                                    <FileInput value={store.rewardImg} onChange={(files) => {
                                        if (files.length) {
                                            store.getBase64(files[0], (value, filename) => {
                                                store.rewardImg = {
                                                    content: value.split('base64,')[1],
                                                    name: filename
                                                }
                                            })
                                        }
                                    }}/>
                                    <small className="btn-notes">jpg, png, максимальный размер 400kb.</small>
                                </div>
                            </div>

                            <h2>Участники</h2>
                            <div className="event-edit-block">
                                <div className="input-column">
                                    <label>Количество участников:</label>
                                    <input type="number" min="0"
                                           value={store.newEventParticipants ? store.newEventParticipants : ''}
                                           onChange={(e) => {
                                               store.newEventParticipants = e.target.value
                                           }}/>
                                </div>
                                <div className="input-column">
                                    <label>Мин. возраст:</label>
                                    <input type="number" min="0" max="99"
                                           value={store.newEventMinAge !== null ? store.newEventMinAge : '0'}
                                           onChange={(e) => {
                                               store.newEventMinAge = e.target.value
                                           }}/>
                                </div>
                                <div className="input-column">
                                    <label>Макс. возраст:</label>
                                    <input type="number" min="0" max="99"
                                           value={store.newEventMaxAge ? store.newEventMaxAge : '99'}
                                           onChange={(e) => {
                                               store.newEventMaxAge = e.target.value
                                           }}/>
                                </div>
                            </div>

                            <div className="input-row">
                                <label>Статус участников:</label>
                                <div className="row break event-buttons">
                                    <button
                                        onClick={() => {
                                            store.newEventStatus = '';
                                        }}
                                        className={"btn light small " + (store.newEventStatus == '' ? '' : 'border')}
                                    >Все
                                    </button>
                                    <button
                                        onClick={() => {
                                            store.newEventStatus = 1;
                                        }}
                                        className={"btn light small " + (store.newEventStatus == 1 ? '' : 'border')}
                                    >Стажёры
                                    </button>
                                    <button
                                        onClick={() => {
                                            store.newEventStatus = 2;
                                        }}
                                        className={"btn light small " + (store.newEventStatus == 2 ? '' : 'border')}
                                    >Юниоры
                                    </button>
                                    <button
                                        onClick={() => {
                                            store.newEventStatus = 3;
                                        }}
                                        className={"btn light small " + (store.newEventStatus == 3 ? '' : 'border')}
                                    >Профи
                                    </button>
                                    <button
                                        onClick={() => {
                                            store.newEventStatus = 4;
                                        }}
                                        className={"btn light small " + (store.newEventStatus == 4 ? '' : 'border')}
                                    >Мастера
                                    </button>
                                </div>
                            </div>


                            <div style={{flex: 1, marginTop: '40px'}}>
                                <label>
                                    Мероприятие в реестре: <input style={{margin: '10px'}} type="checkbox"
                                                                  checked={store.newEventRegistryFlag}
                                                                  onChange={(e) => {
                                                                      store.newEventRegistryFlag = e.target.checked
                                                                  }}
                                />
                                </label>
                            </div>

                            <div style={{flex: 1}}>
                                <label>
                                    При подаче заявки требуется приложение: <input style={{margin: '10px'}}
                                                                                   type="checkbox"
                                                                                   checked={store.newEventReqAttachmentFlag}
                                                                                   onChange={(e) => {
                                                                                       store.newEventReqAttachmentFlag = e.target.checked
                                                                                   }}
                                />
                                </label>
                            </div>

                            <h2>Об организации</h2>
                            <div className="input-row">
                                <label>Полное наименование организации-организатора:</label>
                                <input type="text" className="large"
                                       value={store.newEventOrgName ? store.newEventOrgName : ''}
                                       onChange={(e) => {
                                           store.newEventOrgName = e.target.value
                                       }}/>
                            </div>

                            <div className="input-row">
                                <label>Юридический адрес:</label>
                                <input type="text" className="large"
                                       value={store.newEventOrgAddress ? store.newEventOrgAddress : ''}
                                       onChange={(e) => {
                                           store.newEventOrgAddress = e.target.value
                                       }}/>
                            </div>
                            <div className="input-row event-edit-block">
                                <div className="input-column">
                                    <label>Контактный телефон:</label>
                                    <InputMask mask="+7 (999) 999-99-99" type="text"
                                               value={store.newEventOrgPhone ? store.newEventOrgPhone : ''}
                                               onChange={(e) => {
                                                   store.newEventOrgPhone = e.target.value
                                               }}/>
                                </div>
                                <div className="input-column">
                                    <label>Эл. почта:</label>
                                    <input type="email" value={store.newEventOrgEmail ? store.newEventOrgEmail : ''}
                                           onChange={(e) => {
                                               store.newEventOrgEmail = e.target.value
                                           }}/>
                                </div>
                            </div>

                            <h2>Загрузка документов</h2>

                            {store.newEventFiles.length ?
                                <div>Приложен{store.newEventFiles.length > 1 ? 'ы ' : ''}
                                    документ{store.newEventFiles.length > 1 ? 'ы' : ''}:
                                    {store.newEventFiles.map((file, i) => <div key={'doc-' + i}>
                                        {file.href ? <a href={file.href} target="_blank">{file.name}</a> : file.name}
                                        <button style={{marginLeft: '10px'}} onClick={() => {
                                            store.newEventFiles.splice(i, 1)
                                        }}>Удалить
                                        </button>
                                    </div>)}</div> : null}
                            <div className="input-row">
                                <label className="btn light" style={{width: '150px'}}>
                                    Загрузить документы
                                    <input
                                        multiple="multiple"
                                        style={{display: "none"}}
                                        type="file"
                                        accept="image/*"
                                        onChange={e => {
                                            if (e.target.files.length) {
                                                Array.prototype.forEach.call(e.target.files, (file) => {
                                                    store.getBase64(file, (value, filename) => {
                                                        store.newEventFiles.push({
                                                            content: value.split('base64,')[1],
                                                            name: filename
                                                        });
                                                    })
                                                });
                                            }
                                        }}
                                    />
                                </label>
                            </div>

                            {(store.newEventError || store.serverNewEventError || store.serverError) ?
                                <ul className="row errors-block">
                                    {store.newEventError ? <li>{store.newEventError}</li> : null}
                                    {store.serverNewEventError ? store.renderError(store.serverNewEventError) : null}
                                    {store.serverError ? store.serverError : null}
                                </ul> : null}
                            <div className="row">
                                <button onClick={() => {
                                    store.createEvent();
                                }} className="btn dark large">Сохранить данные
                                </button>
                            </div>
                        </div>
                    )
                }}
            </Observer>
        )}
    </Context.Consumer>;
}