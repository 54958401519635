import React from "react";
import {Observer} from 'mobx-react';
import {Context} from "../utils/Context";
import {
    Link
} from "react-router-dom";

import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import {FileInput, PreviewImg} from "../utils/FileUpload";

import { confirmAlert } from 'react-confirm-alert';

import InputMask from "react-input-mask";
const axios = require('axios');

export default function ({children, ...rest}) {
    return <Context.Consumer>
        {(store) => (
            <Observer>
                {() => (
                    <div className="mobile-padding profile-page">
                        <h1>Личные данные</h1>

                        {(store.profileError || store.profileServerError || store.serverError) ? <ul className="row errors-block">
                            {store.profileError ? <li>{store.profileError}</li> : null}
                            {store.profileServerError ? store.renderError(store.profileServerError) : null}
                            {store.serverError ? store.serverError : null}
                        </ul> : null}

                        {store.user.id?<div className="input-row">
                            <label>ID: {store.user.id}</label>
                        </div>:null}
                        <div className="input-row">
                            <label>Фотография:</label>
                            {store.user.photoToUpload ? <PreviewImg value={store.user.photoToUpload}/>
                                : store.user.photo ? <PreviewImg value={store.user.photo}/> : null}
                            <div className="row">
                                <FileInput value={store.user.photoToUpload} onChange={(files) => {
                                    console.log('Files', files)
                                    if (files.length) {
                                        store.getBase64(files[0], (value, filename) => {
                                            store.user.photoToUpload = {
                                                content: value.split('base64,')[1],
                                                name: filename
                                            }
                                        })
                                    }
                                }}/>
                                <small className="btn-notes">jpg, png, максимальный размер 20 mb.</small>
                            </div>
                        </div>
                        <div className="input-row">
                            <label>Ф.И.О.:</label>
                            <input type="text" className={"large"+(store.profileError=='name'?'error':'')} value={store.user.nameInput ? store.user.nameInput : ''}
                                   onChange={(e) => {
                                       store.user.nameInput = e.target.value
                                   }} placeholder="ФИО"/>
                        </div>

                        <div className="row">
                            <div className="input-row list">
                                <label>Эл. почта:</label>
                                <input type="email" value={store.user.email ? store.user.email : ''} onChange={(e) => {
                                    store.user.email = e.target.value
                                }} placeholder=""/>
                            </div>
                            <div className="input-row list">
                                <label>Номер телефона:</label>
                                <InputMask mask="+7 (999) 999-99-99" type="tel" value={store.user.phone ? store.user.phone : ''} onChange={(e) => {
                                    store.user.phone = e.target.value
                                }} placeholder=""/>
                            </div>
                        </div>


                            {!store.changePasswordInProfile?
                            <div className="row"><div className="input-row list">
                                <button
                                        style={{marginTop:'10px'}}
                                        onClick={()=>{
                                    store.changePasswordInProfile = true;
                                }} className="btn light">Сменить пароль</button>
                            </div></div>:<div className="row">
                            <div className="input-row list">
                                <label>Новый пароль:</label>
                                <input type="password" value={store.newPassword? store.newPassword : ''} onChange={(e) => {
                                    store.newPassword = e.target.value
                                }} placeholder=""/>
                            </div>
                            <div className="input-row list">
                                <label>Повторите новый пароль:</label>
                                <input type="password" value={store.newPasswordRepeat ? store.newPasswordRepeat : ''} onChange={(e) => {
                                    store.newPasswordRepeat = e.target.value
                                }} placeholder=""/>
                            </div>
                            </div>}

                        {store.role=='student'?<div className="input-row">
                            <label>Дата рождения:</label>
                            <input type="date" className="date" value={store.user.birthday ? store.user.birthday : ''}
                                   onChange={(e) => {
                                       store.user.birthday = e.target.value
                                   }} placeholder="ДД.ММ.ГГГГ"/>
                        </div>:null}

                        <div className="row">
                            <div className="list">
                                <label>Домашний адрес</label>
                                 <AsyncSelect
                                    className="select-large geocode"
                                    placeholder="Укажите домашний адрес"
                                    cacheOptions
                                    inputValue={store.currentAddress?store.currentAddress:''}
                                    onInputChange={(inputValue, action)=> {
                                        if (action.action !== "input-blur" && action.action !== "menu-close") {
                                          store.currentAddress = inputValue
                                        }
                                    }}
                                    loadOptions={(inputValue, callback) => {
                                        axios.post(store.host+'/suggest.php', null, { params: {
                                            'q': inputValue
                                        }}).then(function(data){
                                            let result = [];
                                            data = data.data;
                                            if (data.suggestions && data.suggestions.length){
                                                data.suggestions.forEach((el)=>{
                                                    result.push({
                                                        label: el.value,
                                                        value: el.data
                                                    })
                                                })
                                            }
                                            callback(result);
                                        });
                                    }}
                                    noOptionsMessage={() => "Нет записей"}
                                    defaultOptions
                                    onChange={(el) => {
                                        store.user.address = el.label;
                                        store.user.lat = el.value.geo_lat;
                                        store.user.lng = el.value.geo_lon;
                                    }}
                                    value={{label:store.user.address,value:{}}}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="list">
                                <label>{store.role=='teacher'?'Место работы':'Учебное заведение'}:</label>
                                <Select
                                    className="select-large"
                                    value={{label:store.selectedOrganization,value:{}}}
                                    onInputChange={(inputValue, action)=> {
                                        if (action.action !== "input-blur" && action.action !== "menu-close") {
                                          store.selectedOrganization = inputValue
                                        }
                                    }}
                                    placeholder={store.role=='teacher'?"Укажите место работы":"Укажите учебное заведение"}
                                    onChange={(el) => {
                                        store.selectedOrganization = el.label;
                                    }}
                                    noOptionsMessage={() => "Нет записей"}
                                    options={store.organizations}
                                />
                               </div>
                            {store.role=='student'?<div className="input-row list">
                                <label>Класс:</label>
                                <input type="number" min="1" max="11" value={store.user.class ? store.user.class : ''}
                                       onChange={(e) => {
                                           store.user.class = e.target.value
                                       }} className="small"/>
                            </div>:null}
                        </div>
                        {store.role=='student'?<div className="row">
                            <div className="input-row list">
                                <label>Страница во Вконтакте:</label>
                                <input type="text" onChange={(e) => {
                                           store.user.socials.vk = e.target.value
                                       }} value={store.user.socials.vk? store.user.socials.vk : ''} placeholder=""/>
                            </div>
                            <div className="input-row list">
                                <label>Страница в Facebook:</label>
                                <input type="text" onChange={(e) => {
                                           store.user.socials.facebook = e.target.value
                                       }} value={store.user.socials.facebook? store.user.socials.facebook : ''} placeholder=""/>
                            </div>
                            <div className="input-row list">
                                <label>Страница в Instagram:</label>
                                <input type="text" onChange={(e) => {
                                           store.user.socials.instagram = e.target.value
                                       }} value={store.user.socials.instagram? store.user.socials.instagram : ''} placeholder=""/>
                            </div>
                        </div>:null}
                        {store.role=='teacher'?<div className="row"><div className="input-row list">
                                <label>Категория:</label>
                                <select
                                    className="select-large"
                                    value={store.user.cat}
                                    onChange={(e) => {
                                        store.user.cat = e.target.value;
                                    }}
                                >
                                    {store.cats.map((el)=><option key={"cat-"+el.id} value={el.id}>{el.name}</option>)}
                                </select>
                        </div></div>:null}
                        {store.role=='teacher'?<div className="row"><div className="input-row list">
                                <label>Дата последней аттестации:</label>
                                <input type="date" className="date" value={store.user.date_cert ? store.user.date_cert : ''}
                                       onChange={(e) => {
                                           store.user.date_cert = e.target.value
                                       }} placeholder="ДД.ММ.ГГГГ"/>
                        </div></div>:null}
                        {store.role=='teacher'?<div className="row"><div className="input-row list">
                                <label>Курсы повышения квалификации (за 3 последние года):</label>
                                <textarea className="large" value={store.user.certs ? store.user.certs : ''}
                                       onChange={(e) => {
                                           store.user.certs = e.target.value
                                       }}></textarea>
                        </div></div>:null}
                        {store.role=='teacher'?<div className="row"><div className="input-row list">
                                <label>Звание:</label>
                                <textarea className="large" value={store.user.rank ? store.user.rank : ''}
                                       onChange={(e) => {
                                           store.user.rank = e.target.value
                                       }}></textarea>
                        </div></div>:null}

                        <div className="row"><div className="input-row list">
                                <label>О себе:</label>
                                <textarea className="large" value={store.user.about ? store.user.about : ''}
                                       onChange={(e) => {
                                           store.user.about = e.target.value
                                       }}></textarea>
                        </div></div>
                        <div className="input-row">
                            <label>{store.role=='teacher'?'Области экспертных знаний':'Ваши интересы'}:</label>
                            <div className="row break">
                                {store.directions.map((el) => (
                                    <button
                                        onClick={()=>{
                                            if (store.user.directions.indexOf(el.id) > -1) {
                                                store.removeDirection(el.id);
                                            } else {
                                                store.addDirection(el.id);
                                            }
                                        }}
                                        style={{marginRight:'10px'}}
                                        className={"btn light small " + (store.user.directions.indexOf(el.id) > -1 ? '' : 'border')}
                                        key={'direction-' + el.id}
                                    >{el.name}</button>
                                ))}
                            </div>
                        </div>
{!store.user.talanted?(store.role=='teacher'?<div>
                            <h4>Заявка в реестр педагогов</h4>
                            <div style={{margin:'20px 0'}}>
                                <button onClick={()=>{
                                    confirmAlert({
                                      title: 'Заявка в реестр педагогов',
                                      message: 'Вы уверены, что хотите подать заявку в Реестр педагогов, работающих с одаренными детьми в Удмуртской Республике?',
                                      buttons: [
                                        {
                                          label: 'Да',
                                          onClick: () =>{
                                            store.apiRequest('/request', null, (data) => {
                                                store.showConfirmation('Заявка отправлена');
                                            }, function () {
                                            }, 'post')
                                          }
                                        },
                                        {
                                          label: 'Нет'
                                        }
                                      ]
                                    })
                                }} className="btn border">Подать заявку</button>
                            </div>

                        </div>:<div>
                            <h4>Заявка в реестр одарённых учеников</h4>
                            <div style={{margin:'20px 0'}}>
                                <button onClick={()=>{

                                    confirmAlert({
                                      title: 'Заявка в реестр одарённых учеников',
                                      message: 'Вы действительно хотите подать заявку в реестр одаренных учеников?',
                                      buttons: [
                                        {
                                          label: 'Да',
                                          onClick: () =>{
                                            store.apiRequest('/request', null, (data) => {
                                                store.showConfirmation('Заявка отправлена');
                                            }, function () {
                                            }, 'post')
                                          }
                                        },
                                        {
                                          label: 'Нет',
                                          onClick: () => {}
                                        }
                                      ]
                                    });
                                }} className="btn border">Подать заявку</button>
                            </div>

                        </div>):null}

                        {(store.profileError || store.profileServerError || store.serverError) ? <ul className="row errors-block">
                            {store.profileError ? <li>{store.profileError}</li> : null}
                            {store.profileServerError ? store.renderError(store.profileServerError) : null}
                            {store.serverError ? store.serverError : null}
                        </ul> : null}


                        <div className="row"
                                        style={{marginTop:'20px'}}>
                            <button onClick={()=>{
                                store.saveUser();
                            }} className="btn dark large">Сохранить данные</button>
                        </div>
                    </div>
                )}
            </Observer>
        )}
    </Context.Consumer>;
}