import React from "react";
import {Observer} from 'mobx-react';
import {Context} from "../utils/Context";
import {
    Link
} from "react-router-dom";

import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import {FileInput, PreviewImg} from "../utils/FileUpload";
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import dayjs from 'dayjs';
import {confirmAlert} from "react-confirm-alert";

export default function ({children, ...rest}) {
    return <Context.Consumer>
        {(store) => (
            <Observer>
                {() => {
                    if (store.role == 'student') {
                        window.location.href = "/profile";
                        return null;
                    }

                    let parts = window.location.pathname.split('/');
                    let userId = parts[parts.length - 1];

                    if (store.currentUserId != userId) {
                        store.currentUserId = userId;
                        store.getUserById(store.currentUserId);
                    }

                    return <div className="mobile-padding profile-page">
                        <h1>{store.currentEventId?<Link to={"/events/"+store.currentEventId}>
                            <div className="back"></div>
                        </Link>: null}Профиль {store.currentUser.role != 3 ? 'ученика' : 'преподавателя'}</h1>
                        {store.currentUser.role != 3 ?<div>
                                <label>Ф.И.О.: {store.currentUser.name}</label>
                                <label>ID: {store.currentUser.id}</label>
                                <label>Фотография:</label>
                                {store.currentUser.photo ? <PreviewImg value={store.currentUser.photo}/> : null}
                                <label>Эл. почта: {store.currentUser.email?<a href={"mailto:"+store.currentUser.email}>{store.currentUser.email}</a>:''}</label>
                                <label>Номер телефона: {store.currentUser.phone}</label>
                                <label>Дата рождения: {store.currentUser.birthday}</label>
                                <label>Домашний адрес: {store.currentUser.address}</label>
                                <label>Учебное заведение: {store.currentUser.organization}</label>
                                <label>Класс: {store.currentUser.class}</label>
                                <label>Страница во Вконтакте: {store.currentUser.social_urls?store.currentUser.social_urls.vk:'не указана'}</label>
                                <label>Страница в Facebook: {store.currentUser.social_urls?store.currentUser.social_urls.facebook:'не указана'}</label>
                                <label>Страница в Instagram: {store.currentUser.social_urls?store.currentUser.social_urls.instagram:'не указана'}</label>
                                <label>О себе: {store.currentUser.about}</label>
                                <label>Интересы: {store.currentUser.directions?(store.directions.map((el)=>store.currentUser.directions.indexOf(el.id) > -1?el.name:'')):'не указаны'}</label>
                                <label>В реестре одарённых учеников: {store.currentUser.talanted?'Да':'Нет'}</label>
                        </div>:<div>
                                <label>Ф.И.О.: {store.currentUser.name}</label>
                                <label>ID: {store.currentUser.id}</label>
                                <label>Фотография:</label>
                                {store.currentUser.photo ? <PreviewImg value={store.currentUser.photo}/> : null}
                                <label>Эл. почта: {store.currentUser.email?<a href={"mailto:"+store.currentUser.email}>{store.currentUser.email}</a>:''}</label>
                                <label>Номер телефона: {store.currentUser.phone}</label>
                                <label>Дата рождения: {store.currentUser.birthday}</label>
                                <label>Домашний адрес: {store.currentUser.address}</label>
                                <label>Место работы: {store.currentUser.organization}</label>
                                <label>Категория: {store.currentUser.cats?(store.cats.map((el)=>store.currentUser.cat==el.id?el.name:'')):'не указана'}</label>
                                <label>Дата последней аттестации: {store.currentUser.date_cert?store.currentUser.date_cert:'не указана'}</label>
                                <label>Курсы повышения квалификации (за 3 последние года): {store.currentUser.certs?store.currentUser.certs:'не указаны'}</label>
                                <label>Звание: {store.currentUser.rank?store.currentUser.rank:'не указано'}</label>
                                <label>О себе: {store.currentUser.about}</label>
                                <label>Области экспертных знаний: {store.currentUser.directions?(store.directions.map((el)=>store.currentUser.directions.indexOf(el.id) > -1?el.name:'')):'не указаны'}</label>
                                <label>В реестре преподавателей: {store.currentUser.talanted?'Да':'Нет'}</label>
                            </div>
                        }
                    </div>
                }}
            </Observer>
        )}
    </Context.Consumer>;
}