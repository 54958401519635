import {Redirect, useHistory, useLocation} from "react-router-dom";
import React from "react";
import {Observer} from 'mobx-react';
import {Context} from "../utils/Context";

import {
    Link
} from "react-router-dom";

export default class LogoutPage extends React.Component {
  render() {
    return <Context.Consumer>{(store) =>
        <Observer>{() => {
            store.logout();
            return (
                <div></div>
            )
        }}</Observer>}
        </Context.Consumer>
    }
}