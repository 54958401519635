import {Redirect, useHistory, useLocation} from "react-router-dom";
import React from "react";
import {Observer} from 'mobx-react';
import {Context} from "../utils/Context";

import {
    Link
} from "react-router-dom";

class ForgotPassword extends React.Component {
  render() {
    return <Context.Consumer>{(store) =>
        <Observer>{() => {
            let location = useLocation();
            return (
                store.isAuthenticated ? <Redirect
                    to={{
                      pathname: "/",
                      state: { from: location }
                    }}
                  /> : <div>
                    <header>
                        <div className="wrapper">
                            <a href="#" className="logo">Центр одаренных детей</a>
                        </div>
                    </header>
                    <div className="content main centered">
                        <div className="single column">
                            <h1 style={{
                                flexDirection: 'row',
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                                <div>Восстановление пароля</div>
                                <Link style={{fontSize:'14px'}} to="/login">Вход в систему</Link>
                            </h1>
                            <div className="column">
                                {(store.changePasswordError||store.serverError) ? <ul className="row errors-block">
                                    {store.changePasswordError?<li>{store.changePasswordError}</li>:null}
                                    {store.serverError?<li>{store.serverError}</li>:null}
                                </ul> : null}
                                <div className="input-row">
                                    <label htmlFor="f-password">Эл. почта:</label>
                                    <input id="f-password"
                                           className={'full ' + (store.changePasswordError ? "error" : "")}
                                           type="email" value={store.changePasswordEmail} onChange={(e) => {
                                        store.changePasswordEmail = e.target.value
                                    }}/>
                                </div>

                                <div className="row">
                                    <button disabled={store.loading} onClick={() => {
                                        store.forgotPasswordRequest()
                                    }} className="btn dark large">Отправить</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }}</Observer>}
        </Context.Consumer>
    }
}
export default ForgotPassword