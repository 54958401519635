import React from "react";
import {
    useHistory,
    useLocation
} from "react-router-dom";

import history from "./utils/History";
import dayjs from 'dayjs';
import {EditorState, convertToRaw} from 'draft-js';
import draftToHtml from 'draftjs-to-html';

const axios = require('axios');


export default {
    host: window.location.host == 'localhost:3000' ? 'https://tau.nbr9.com' : '',
    count: 0,
    accessToken: null,
    isAuthenticated: false,
    isInitialized: false,

    done: true,
    signupPasswordError: false,
    signupEmailError: false,
    signupNameError: false,
    signupTermsError: false,
    authLoginError: false,
    authPasswordError: false,
    authLoading: false,
    authMessage: false,
    authLogin: '',
    authPassword: '',
    authStoreAuth: false,


    signupLoading: false,
    signupTerms: false,
    signupName: '',
    signupEmail: '',
    signupPassword: '',

    newAchievementTitle: '',
    newAchievementDescription: '',
    newAchievementFlag: false,
    newAchievementImage: false,
    newAchievementType: 0,
    newAchievementTypeId: 0,
    newAchievementLevel: 0,
    newAchievementDirection: 0,
    newAchievementError: null,
    achievementErrorField: null,

    newDocTitle: '',
    newDocFile: null,
    newDocError: null,

    emailConfirmed: false,
    emailConfirmationError: false,

    changePasswordError: false,

    passwordChanged: false,
    changePasswordEmail: '',
    changePassword: '',

    changePasswordInProfile: false,
    newPassword: '',
    newPasswordRepeat: '',


    profileError: false,
    profileServerError: false,
    mobileMenuShown:false,

    loading: false,
    serverError: null,
    role: 'student',
    user: {
        id: null,
        name: '',
        rank: '',
        category: '',
        socials: {
            vk: '',
            facebook: '',
            instagram: ''
        }
    },
    organizations: [],
    directions: [],
    cats:[],
    types: [],
    levels: [],
    events: [],
    eventsType: 'requested',
    tests: [],
    testsType: 'all',
    achievements: [],
    achievementTypes: [],
    addAchievementModalShown: false,
    docs: [],
    addDocModalShown: false,
    currentAchievement: null,
    currentDoc: null,
    messages: [],
    topicsSeen: [],
    draftMessages: {},
    messageAttachements: [],
    messageSending: false,
    newMessagesCount: 0,

    selectedOrganization: '',
    currentAddress: '',
    confirmation: null,
    errorMessage: null,
    headerPopup: false,
    importantAchievementsAllowed: true,
    getMessagesTimeout: 0,

    newEventId: null,
    newEventError: false,
    serverNewEventError: null,
    newEventName: '',
    newEventDirection: 0,
    newEventType: 0,
    newEventLevel: 0,
    newEventPhoto: null,
    newEventPhotoSrc: null,
    rewardImg: null,
    rewardImgSrc: null,
    newEventFiles: [],
    newEventDate: null,
    newEventStart: null,
    newEventEnd: null,
    newEventParticipants: 0,
    newEventMinAge: 0,
    newEventMaxAge: 99,
    newEventOrgEmail: '',
    newEventOrgPhone: '',
    newEventRegistryFlag:false,
    newEventReqAttachmentFlag:false,
    newEventAddressObj: {
        address: '',
        lat: 0,
        lng: 0
    },
    newEventStatus: 1,
    newEventChRep: false,
    newEventChRos: false,
    editorState: EditorState.createEmpty(),
    currentRequests: [],
    currentEventId: null,
    showSidebar: true,
    currentTestQuestion: 0,
    currentAnswer: null,
    answers: [],
    notifications: [],
    currentRecepient: '',

    testResult: null,
    testScore: 0,
    currentRequestEvent:0,
    requestAttachment: null,
    requestAttachmentDescription: '',
    requestAttachmentError:false,
    currentSelectedUsers: [],
    currentMultiplePosition: 0,
    currentUser:{},

    showLoginButtons: false,

    apiRequest(action, params, cb, errCb, method) {

        this.serverError = null;
        let self = this;
        let headers = {};
        if (!method) {
            method = 'get'
        }
        if (params) {
            headers['content-type'] = 'application/json';
        }
        if (this.accessToken && this.accessToken.length) {
            headers['Authorization'] = "Bearer " + this.accessToken;
        } else {
            if (["/user/login",
                "/user/login-by-social",
                "/user/signup",
                "/user/password-reset",
                "/user/password-reset-request",
                "/user/confirm"].indexOf(action) == -1) {
                return null;
            }
        }
        (method == 'get' ? axios[method](this.host + '/v1' + action, {
            headers
        }) : method == 'delete' ? axios[method](this.host + '/v1' + action, {
            headers
        }) : axios[method](this.host + '/v1' + action, params, {
            headers
        }))
            .then(function (response) {
                //console.log(response);
                self.authLoading = false;
                if (response.data) {
                    if (response.data.success) {
                        if (response.data.data) {
                            if (typeof(cb) == 'function') cb(response.data.data)
                        } else {
                            if (typeof(cb) == 'function') cb(response.data)
                        }
                    } else {
                        if (typeof(errCb) == 'function') errCb(response.data)
                    }
                }
                if (method == 'delete') {
                    if (typeof(cb) == 'function') cb()
                }
            })
            .catch(function (error) {
                self.authLoading = false;
                if (error.response && error.response.data) {
                    let data = error.response.data.data;
                    if (data && data.message) {
                        try {
                            data.message = JSON.parse(data.message);
                        } catch (e) {
                        }
                        if (data.status == 401) {
                            //    self.isAuthenticated = false;
                        }
                        if (Object.entries(data.message)) {
                            self.showErrorMessage(Object.values(data.message));
                        }
                        if (typeof(errCb) == 'function') errCb(data)

                    } else {
                        if (typeof(errCb) == 'function') errCb(error.response.data)
                    }
                } else {
                    if (typeof(errCb) == 'function') errCb("Ошибка при подключении к серверу")
                }
            });
    },
    validateEmail(email) {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    },
    setCookie(name, value, days) {
        let expires = "";
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/; Domain=.tau18.ru";
    },
    getCookie(name) {
        let nameEQ = name + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    },
    eraseCookie(name) {
        document.cookie = name + '=; Max-Age=-99999999; Domain==.tau18.ru';
    },
    login(cb) {
        let self = this;
        let valid = true;
        if (this.authLogin == '') {
            this.authLoginError = 'Заполните логин';
            valid = false;
        } else {
            if (!this.validateEmail(this.authLogin)) {
                this.authLoginError = 'Укажите корректный email';
                valid = false;
            } else {
                this.authLoginError = false
            }
        }
        if (this.authPassword == '') {
            this.authPasswordError = 'Заполните пароль';
            valid = false;
        } else {
            this.authPasswordError = false
        }
        if (valid) {
            self.authMessage = false;
            self.passwordChanged = false;
            this.authLoading = true;
            this.apiRequest('/user/login', {
                "LoginForm": {
                    "username": this.authLogin,
                    "password": this.authPassword
                }
            }, (data) => {
                console.log('Received', data);
                self.storeTokens(data);
            }, (errors) => {
                if (typeof(errors) == 'object' && errors.message && errors.message.password) {
                    self.authLoginError = errors.message.password;
                } else {
                    self.serverError = errors;
                }
            }, 'post');
            //this.isAuthenticated = true;
            //if (cb) cb();
        }
    },
    storeTokens(data){
        let self = this;
        if (self.authStoreAuth) {
            localStorage.setItem('user_id', data.id);
            localStorage.setItem('access_token', data.access_token);
            localStorage.setItem('refresh_token', data.refresh_token);
            self.setCookie('access_token', data.access_token, 14);
            self.setCookie('refresh_token', data.refresh_token, 14);
        } else {
            sessionStorage.setItem('user_id', data.id);
            sessionStorage.setItem('access_token', data.access_token);
            sessionStorage.setItem('refresh_token', data.refresh_token);
            self.setCookie('access_token', data.access_token, 0);
            self.setCookie('refresh_token', data.refresh_token, 0);
        }
        self.authMessage = '';
        self.isAuthenticated = true;
        self.accessToken = data.access_token;
        self.user.id = data.id;
        self.init();
    },
    renderError: function (errors) {
        console.log('errors', errors);
        let result = [];
        if (errors) {
            if (typeof(errors) == 'string') {
                result.push(<li key={'server-error'}>
                    {errors}
                </li>)
            } else {
                if (errors.forEach){
                    errors.forEach((el, key) => {
                        result.push(<li key={'server-error-' + key}>
                            {typeof(el.message) == 'string' ? el.message : el.length&&el.map ? el.map((er, i) => {
                                return <span key={i}>{er}</span>
                            }) : 'Ошибка сохранения данных'}
                        </li>)
                    })
                }
            }
        }
        return result;
    },
    logout() {
        this.eraseCookie('access_token');
        this.eraseCookie('refresh_token');
        sessionStorage.clear();
        localStorage.clear();
        this.isAuthenticated = false;
        window.location.href = "/";
    },

    signup(cb) {
        this.signupLoading = false;
        let valid = true;
        if (!this.signupTerms) {
            this.signupTermsError = 'Для регистрации необходимо согласие на обработку персональных данных';
            valid = false;
        } else {
            this.signupTermsError = null;
        }
        if (this.signupEmail == '') {
            this.signupEmailError = 'Заполните эл. почту';
            valid = false;
        } else {
            if (!this.validateEmail(this.signupEmail)) {
                this.signupEmailError = 'Укажите корректный email';
                valid = false;
            } else {
                this.signupEmailError = false
            }
        }
        if (this.signupPassword == '') {
            this.signupPasswordError = 'Заполните пароль';
            valid = false;
        } else {
            if (this.signupPassword.length < 6) {
                this.signupPasswordError = 'Пароль должен быть длиной не менее 6 символов';
                valid = false;
            } else {
                this.signupPasswordError = false
            }
        }
        if (this.signupName == '') {
            this.signupNameError = 'Заполните Ф.И.О.';
            valid = false;
        } else {
            this.signupNameError = false
        }
        if (valid) {
            let self = this;
            this.signupLoading = true;
            this.apiRequest('/user/signup', {
                "SignupForm": {
                    "name": this.signupName,
                    "password": this.signupPassword,
                    "email": this.signupEmail
                }
            }, (data) => {
                this.signupLoading = false;
                console.log('Received', data);
                if (data) {
                    self.authLogin = self.signupEmail;
                    self.authPassword = self.signupPassword;
                    self.authMessage = 'Регистрация проведена успешно, проверьте e-mail для активации аккаунта (письмо может попасть в папку Спам)';
                    history.push("/login");
                }
            }, (errors) => {
                this.signupLoading = false;
                if (typeof(errors) == 'object' && errors.message) {
                    if (errors.message.email) {
                        this.signupEmailError = errors.message.email;
                    }
                    if (errors.message.name) {
                        this.signupNameError = errors.message.name;
                    }
                    if (errors.message.password) {
                        this.signupPasswordError = errors.message.password;
                    }
                } else {
                    this.serverError = errors;
                }
            }, 'post');
            //this.isAuthenticated = true;
            //if (cb) cb();
        }
    },
    getById(bucket, id) {
        let backut = this[bucket];
        if (this[bucket] && this[bucket].length) {
            for (let i = 0; i <= this[bucket].length; i++) {
                if (this[bucket][i] && this[bucket][i].id == id) {
                    return this[bucket][i]
                }
            }
        }
        return null;
    },
    findGetParameter(parameterName) {
        let result = null,
            tmp = [];
        window.location.search
            .substr(1)
            .split("&")
            .forEach(function (item) {
                tmp = item.split("=");
                if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
            });
        return result;
    },

    confirm(id, token, cb, errCb) {
        console.log('confirm', id, token);
        let self = this;
        this.apiRequest('/user/confirm', {
            "EmailConfirmForm": {
                "id": id,
                "email_verefy_token": token
            }
        }, (data) => {
            console.log('Confirmed email', data);
            cb()
        }, (errors) => {
            console.error(errors);
            if (typeof(errors) == 'object' && errors.message) {
                errCb(errors.message.email_verefy_token);
            }
        }, 'post')
    },

    forgotPasswordRequest() {
        let valid = true;
        this.loading = false;
        if (this.changePasswordEmail == '') {
            this.changePasswordError = 'Введите эл. почту';
            valid = false;
        } else {
            this.changePasswordError = false
        }
        if (valid) {
            let self = this;
            this.loading = true;
            this.apiRequest('/user/password-reset-request', {
                "PasswordResetRequestForm": {
                    email: self.changePasswordEmail
                }
            }, (data) => {
                self.loading = false;
                self.passwordChanged = true;
                history.push("/");
            }, (errors) => {
                self.loading = false;
                console.error(errors);
                if (typeof(errors) == 'object' && errors.message) {
                    self.changePasswordError = JSON.stringify(errors.message);
                }
            }, 'post');
        }
    },
    changePasswordRequest() {
        let self = this;
        self.loading = false;
        let valid = true;
        if (!this.changePassword.length) {
            this.changePasswordError = 'Введите пароль';
            valid = false;
        }
        if (this.changePassword.length < 6) {
            this.changePasswordError = 'Длина пароля должна быть не менее 6 символов';
            valid = false;
        }
        if (valid) {
            self.loading = true;
            this.apiRequest('/user/password-reset', {
                PasswordResetForm: {
                    token: this.findGetParameter('token'),
                    "newPassword": this.changePassword,
                    "confirmPassword": this.changePassword
                }
            }, (data) => {
                self.loading = false;
                if (data) {
                    self.authMessage = false;
                    self.passwordChanged = false;
                    history.push("/");
                }
            }, (errors) => {
                self.loading = false;
                console.error(errors);
                if (typeof(errors) == 'object' && errors.message) {
                    if (errors.message['newPassword']) {
                        self.changePasswordError = errors.message['newPassword'];
                    } else {
                        self.changePasswordError = errors.message.token ? errors.message.token : JSON.stringify(errors.message);
                    }
                }
            }, 'put');
        }
    },

    addDirection(id) {
        let self = this;
        this.apiRequest('/user-direction', {
            "direction_id": id
        }, (data) => {
            if (self.user.directions.indexOf(id) == -1) {
                self.user.directions.push(id)
            }
        }, (errors) => {
        }, 'post');
    },

    removeDirection(id) {
        let self = this;
        this.apiRequest('/user-direction/' + id, {}, (data) => {
            console.log(self.user.directions);
            self.user.directions.splice(self.user.directions.indexOf(id), 1);
            console.log(self.user.directions);
        }, (errors) => {
        }, 'delete');
    },


    editAchievement() {
        if (this.currentAchievement.name == '') {
            this.editAchievementError = 'Укажите название награды';
            return;
        }
        if (this.currentAchievement.description == '') {
            this.editAchievementError = 'Укажите описание награды';
            return;
        }
        if (!this.currentAchievement.preview) {
            this.editAchievementError = 'Укажите изображение награды';
            return;
        }
        if (!this.currentAchievement.direction_id) {
            this.editAchievementError = 'Укажите направление';
            return;
        }
        if (!this.currentAchievement.type_id) {
            this.editAchievementError = 'Укажите тип';
            return;
        }
        if (!this.currentAchievement.level_id) {
            this.editAchievementError = 'Укажите уровень';
            return;
        }
        let self = this;
        let data = {
            "name": this.currentAchievement.name,
            "description": this.currentAchievement.description,
            "direction_id": this.currentAchievement.direction_id,
            "achievement_type_id": this.currentAchievement.achievement_type_id,
            "type_id": this.currentAchievement.type_id,
            "level_id": this.currentAchievement.level_id
        };
        if (this.currentAchievement.preview && this.currentAchievement.preview.content) {
            data.file = this.currentAchievement.preview
        }
        if (!this.loading) {
            this.loading = true;
            this.apiRequest('/achievement/' + self.currentAchievement.id, {
                "Achievement": data
            }, (data) => {
                this.loading = false;
                self.closeAchievementModal();
            }, (errors) => {
                this.loading = false;
            }, 'put');
        }
    },

    removeAchievement() {
        let self = this;
        this.loading = true;
        this.apiRequest('/achievement/' + self.currentAchievement.id, {}, (data) => {
            this.loading = false;
            self.closeAchievementModal();
        }, (errors) => {
            this.loading = false;
        }, 'delete');
    },

    closeAchievementModal() {
        if (this.currentAchievement) {
            this.currentAchievement.editable = false;
            this.currentAchievement = null;
        }
        this.addAchievementModalShown = null;
        this.getAchievements();
    },
    setAchievementImportantStatus(value) {
        let self = this;
        this.apiRequest('/achievement/' + self.currentAchievement.id, {
            "Achievement": {
                important: value
            }
        }, (data) => {
            self.closeAchievementModal();
        }, (errors) => {
        }, 'put');
    },

    saveDoc() {
        if (this.newDocTitle == '') {
            this.newDocError = 'Укажите название документа';
            return;
        }
        if (!this.newDocFile) {
            this.newDocError = 'Выберите файл';
            return;
        }
        let self = this;
        this.apiRequest('/user-file', {
            "description": this.newDocTitle,
            "content": this.newDocFile.content,
            "name": this.newDocFile.name
        }, (data) => {
            self.closeDocModal();
        }, (errors) => {
            self.closeDocModal();
        }, 'post');
    },


    closeDocModal() {
        this.addDocModalShown = null;
        this.getDocs();
    },

    saveAchievement() {
        if (this.newAchievementTitle == '') {
            this.achievementErrorField = 'title';
            this.newAchievementError = 'Укажите название награды';
            return;
        }
        if (this.newAchievementDescription == '') {
            this.achievementErrorField = 'description';
            this.newAchievementError = 'Укажите описание награды';
            return;
        }
        if (!this.newAchievementImage) {
            this.achievementErrorField = 'image';
            this.newAchievementError = 'Укажите изображение награды';
            return;
        }

        if (!parseInt(this.newAchievementDirection)) {
            this.achievementErrorField = 'direction';
            this.newAchievementError = 'Укажите направление';
            return;
        }
        if (!parseInt(this.newAchievementType)) {
            this.achievementErrorField = 'type';
            this.newAchievementError = 'Укажите тип';
            return;
        }
        if (!parseInt(this.newAchievementTypeId)) {
            this.achievementErrorField = 'achievement_type';
            this.newAchievementError = 'Укажите вид награды';
            return;
        }
        if (!parseInt(this.newAchievementLevel)) {
            this.achievementErrorField = 'level';
            this.newAchievementError = 'Укажите уровень';
            return;
        }
        let self = this;
        if (!this.loading) {
            this.loading = true;
            this.apiRequest('/achievement', {
                "Achievement": {
                    "name": this.newAchievementTitle,
                    "description": this.newAchievementDescription,
                    "direction_id": this.newAchievementDirection,
                    "type_id": this.newAchievementType,
                    "level_id": this.newAchievementLevel,
                    "achievement_type_id": this.newAchievementTypeId,
                    "important": false,
                    "tau": this.newAchievementFlag,
                    "date": dayjs().format('YYYY-MM-DD'),
                    "file": this.newAchievementImage
                }
            }, (data) => {
                self.loading = false;
                self.closeAchievementModal();
            }, (errors) => {
                self.showErrorMessage(errors.data);
                self.loading = false;
                self.closeAchievementModal();
            }, 'post');
        }
        this.achievementErrorField = null;
    },

    createEvent() {
        const rawContentState = convertToRaw(this.editorState.getCurrentContent());
        const markup = draftToHtml(rawContentState);

        this.newEventError = null;

        if (!this.newEventName || !this.newEventName.length) {
            this.newEventError = 'Укажите название мероприятия';
            return;
        }
        if (this.newEventName.length > 140) {
            this.newEventError = 'Название мероприятия - не более 140 символов';
            return;
        }
        if (!this.newEventDirection) {
            this.newEventError = 'Укажите направление мероприятия';
            return;
        }
        if (!this.newEventType) {
            this.newEventError = 'Укажите тип мероприятия';
            return;
        }
        if (!this.newEventLevel) {
            this.newEventError = 'Укажите уровень мероприятия';
            return;
        }


        if (!this.editorState.getCurrentContent().hasText()) {
            this.newEventError = 'Укажите описание мероприятия';
            return;
        }
        if (!this.newEventDate) {
            this.newEventError = 'Укажите дату проведения';
            return;
        }
        if (!this.newEventStart) {
            this.newEventError = 'Укажите начало регистрации';
            return;
        }
        if (!this.newEventEnd) {
            this.newEventError = 'Укажите окончание регистрации';
            return;
        }
        if (dayjs(this.newEventEnd) < dayjs(this.newEventStart)) {
            this.newEventError = 'Время начала регистрации должно быть раньше времени окончания';
            return;
        }
        if (dayjs(this.newEventDate) < dayjs(this.newEventStart)) {
            this.newEventError = 'Дата проведения должна быть позднее даты начала регистрации';
            return;
        }
        if (!this.newEventOrgName || !this.newEventOrgName.length) {
            this.newEventError = 'Укажите наименование организатора';
            return;
        }
        if (this.newEventParticipants < 1) {
            this.newEventError = 'Укажите количество участников';
            return;
        }
        if (this.newEventMinAge < 0) {
            this.newEventError = 'Укажите мин. возраст';
            return;
        }

        if (this.newEventOrgPhone.indexOf('_') > -1) {
            this.newEventError = "Заполните поле Телефон";
            return;
        }
        if (parseInt(this.newEventMinAge) > parseInt(this.newEventMaxAge)) {
            this.newEventError = 'Макс. возраст должен быть больше минимального';
            return;
        }
        let self = this;
        if (!this.newEventId) {
            this.apiRequest('/event', {
                "Event": {
                    "name": this.newEventName,
                    "organization": this.newEventOrgName,
                    "direction_id": this.newEventDirection,
                    "type_id": this.newEventType,
                    "level_id": this.newEventLevel,
                    "description": markup,
                    "regStart": this.newEventStart + ' 00:00:00',
                    "regEnd": this.newEventEnd + ' 00:00:00',
                    "start": this.newEventDate + ' 00:00:00',
                    "end": this.newEventDate + ' 00:00:00',
                    "org_address": this.newEventOrgAddress,
                    "org_phone": this.newEventOrgPhone,
                    "org_email": this.newEventOrgEmail,
                    "location": this.newEventAddressObj.address,
                    "lat": this.newEventAddressObj.lat,
                    "lng": this.newEventAddressObj.lng,
                    "max_participants": this.newEventParticipants,
                    "status": this.newEventStatus,
                    "ageMin": this.newEventMinAge,
                    "ageMax": this.newEventMaxAge,
                    "registry": this.newEventRegistryFlag,
                    "req_attach": this.newEventReqAttachmentFlag,
                    "template": "",
                    "author": this.user.id,
                    "reward": this.newEventAwards,
                    "mySkin": this.newEventPhoto,
                    "rewardImg": this.rewardImg,
                    "files": this.newEventFiles.length ? this.newEventFiles : null
                }
            }, (data) => {
                self.getEvents();
                history.push("/events");
            }, (errors) => {
                self.serverNewEventError = errors.data;
            }, 'post');
        } else {
            let data = {
                "name": this.newEventName,
                "organization": this.newEventOrgName,
                "direction_id": this.newEventDirection,
                "type_id": this.newEventType,
                "level_id": this.newEventLevel,
                "description": markup,
                "regStart": this.newEventStart + ' 00:00:00',
                "regEnd": this.newEventEnd + ' 00:00:00',
                "start": this.newEventDate + ' 00:00:00',
                "end": this.newEventDate + ' 00:00:00',
                "org_address": this.newEventOrgAddress,
                "org_phone": this.newEventOrgPhone,
                "org_email": this.newEventOrgEmail,
                "location": this.newEventAddressObj.address,
                "lat": this.newEventAddressObj.lat,
                "lng": this.newEventAddressObj.lng,
                "max_participants": this.newEventParticipants,
                "status": this.newEventStatus,
                "ageMin": this.newEventMinAge,
                "ageMax": this.newEventMaxAge,
                "template": "",
                "author": this.user.id,
                "reward": this.newEventAwards
            }
            if (this.newEventPhoto) {
                data["mySkin"] = this.newEventPhoto;
            }
            if (this.rewardImg) {
                data["rewardImg"] = this.rewardImg;
            }
            if (this.newEventFiles.length && this.newEventFiles[0].content) {
                data["files"] = this.newEventFiles;
            }
            this.apiRequest('/event/' + this.newEventId, {
                "Event": data
            }, (data) => {
                self.getEvents();
                history.push("/events");
            }, (errors) => {
                self.serverNewEventError = errors.data;
            }, 'put');
        }

    },
    completeEvent(eventId) {
        let self = this;

        let event = null;

        self.events.forEach((el) => {
            if (eventId == el.id) {
                event = el;
            }
        });

        if (event.requests.length){
            event.requests.forEach((req)=>{
                if (req.status !== 1) {
                    self.rejectRequestByTeacher(req.id)
                }
            })
        }

        this.apiRequest('/event/' + eventId, {
            "Event": {
                event_status: 3
            }
        }, (data) => {
            self.getEvents();
        }, (errors) => {
            self.serverNewEventError = errors.data;
        }, 'put');
    },
    resetEvent() {
        this.newEventId = null;
        this.newEventError = false;
        this.serverNewEventError = null;
        this.newEventName = '';
        this.newEventDirection = 0;
        this.newEventType = 0;
        this.newEventLevel = 0;
        this.newEventPhoto = null;
        this.newEventPhotoSrc = null;
        this.newEventRegistryFlag = false;
        this.rewardImg = null;
        this.rewardImgSrc = null;
        this.newEventFiles = [];
        this.newEventDate = null;
        this.newEventStart = null;
        this.newEventEnd = null;
        this.newEventParticipants = 0;
        this.newEventMinAge = 0;
        this.newEventMaxAge = 99;
        this.newEventOrgName = '';
        this.newEventOrgAddress = '';
        this.newEventOrgEmail = '';
        this.newEventOrgPhone = '';
        this.newEventAddressObj = {
            address: '',
            lat: 0,
            lng: 0
        };
    },

    showConfirmation(message) {
        this.confirmation = message;
        let self = this;
        setTimeout(() => self.confirmation = null, 3000);
    },
    showErrorMessage(message) {
        if (!message.length) return;
        this.errorMessage = message;
        let self = this;
        setTimeout(() => self.errorMessage = null, 3000);
    },
    saveUser(cb, errorCb) {
        this.profileError = null;
        if (!this.user.phone || this.user.phone.indexOf('_') > -1) {
            this.profileError = "Заполните поле Телефон";
            return;
        }
        let self = this;
        let data = {
            "email": this.user.email,
            "name": this.user.nameInput,
            "phone": this.user.phone,
            "birthday": this.user.birthday,
            "organization": this.selectedOrganization,
            "social_urls": JSON.stringify({
                vk: this.user.socials.vk,
                facebook: this.user.socials.facebook,
                instagram: this.user.socials.instagram,
            })
        };

        if (this.user.class) {
            data['class'] = this.user.class;
        }
        if (this.user.date_cert) {
            data['date_cert'] = this.user.date_cert;
        }
        if (this.user.rank) {
            data['rank'] = this.user.rank;
        }
        if (this.user.about) {
            data['about'] = this.user.about;
        }
        if (this.user.cat) {
            data['cat'] = this.user.cat;
        }

        if (this.user.certs) {
            data['certs'] = this.user.certs;
        }
        if (this.user.photoToUpload && this.user.photoToUpload.content) {
            data['myPhoto'] = this.user.photoToUpload.content;
        }
        if (this.user.address) {
            data['address'] = this.user.address;
        }
        if (this.user.lat) {
            data['lat'] = this.user.lat;
            data['lng'] = this.user.lng;
        }
        if (this.user.certs) {
            data['certs'] = this.user.certs;
        }
        if (this.user.date_cert) {
            data['date_cert'] = this.user.date_cert;
        }
        if (this.user.cat) {
            data['cat'] = this.user.cat;
        }
        console.log('User data', data);
        let userData = data;
        console.log('changePasswordInProfile', this.changePasswordInProfile);
        if (this.changePasswordInProfile) {
            this.apiRequest('/user/password', {
                "PasswordChangeForm": {
                    newPassword: this.newPassword,
                    confirmPassword: this.newPasswordRepeat
                }
            }, (data) => {
                self.sendUserData(userData, cb)
            }, (errors) => {
                self.profileServerError = errors.data;
            }, 'put')
        } else {
            this.sendUserData(userData, cb)
        }

    },
    sendUserData(data, cb) {
        let self = this;
        this.apiRequest('/user/' + this.user.id, {
            "UserForm": data
        }, (data) => {
            self.profileServerError = '';
            console.log('Put user', data);
            self.showConfirmation('Изменения успешно сохранены');
            self.getUser();
            if (cb) cb();
        }, (errors) => {
            self.profileServerError = errors.data;
            if (!errors.message.length){
                self.profileServerError = "Телефонный номер уже используется в другом аккаунте";
            }
        }, 'put')
    },
    getUser(cb, errorCb) {
        let self = this;
        this.apiRequest('/user/' + this.user.id, null, (data) => {
            if (data.role == 1 && window.location.href.indexOf('logout') == -1) {
                if (window.location.href.indexOf('/admin/event') == -1) {
                    window.location.href = '/admin/event';
                    return null;
                }
            }
            if (data.social_urls) {
                data.socials = JSON.parse(data.social_urls);
            } else {
                data.socials = self.user.socials;
            }
            data.nameInput = data.name;
            if (data.organization) {
                self.selectedOrganization = data.organization;
            }
            self.currentAddress = data.address;
            self.role = (data.role == 3 ? 'teacher' : 'student');
            self.user = data;

            console.log('user', data);
            if (cb) cb();
        }, errorCb)
    },

    getUserById(userId, cb, errorCb) {
        let self = this;
        self.loading = true;
        this.apiRequest('/user/' + userId, null, (data) => {
            if (data.social_urls) {
                data.social_urls = JSON.parse(data.social_urls);
            }
            self.currentUser = data;
            self.loading = false;
            console.log('user', data);
            if (cb) cb();
        }, errorCb)
    },
    getNotifications(cb, errorCb) {
        let self = this;
        this.apiRequest('/notification', null, (data) => {
            console.log('Received notifications', data);
            self.notifications = data;
            if (cb) cb();
        }, errorCb)
    },
    getOrganizations(cb, errorCb) {
        let self = this;
        this.apiRequest('/organization', null, (data) => {
            console.log('Received organizations', data);
            let result = [];
            if (data.length) {
                data.forEach((el) => {
                    result.push({
                        value: el.id,
                        label: el.name
                    });
                })
            }
            self.organizations = result;
            if (cb) cb();
        }, errorCb)
    },
    getTypes(cb, errorCb) {
        let self = this;
        this.apiRequest('/type', null, (data) => {
            console.log('Received types', data);
            self.types = data;
            if (cb) cb();
        }, errorCb)
    },
    getLevels(cb, errorCb) {
        let self = this;
        this.apiRequest('/level', null, (data) => {
            console.log('Received levels', data);
            self.levels = data;
            if (cb) cb();
        }, errorCb)
    },
    getDirections(cb, errorCb) {
        let self = this;
        this.apiRequest('/direction', null, (data) => {
            console.log('Received directions', data);
            self.directions = data;
            if (cb) cb();
        }, errorCb)
    },
    getTests(cb, errorCb) {
        let self = this;
        this.apiRequest('/test', null, (data) => {
            console.log('Received tests', data);
            self.tests = data;
            if (cb) cb();
        }, errorCb)
    },
    getEvents(cb, errorCb) {
        let self = this;
        this.apiRequest('/event?per-page=1000', null, (data) => {
            console.log('Received events', data);
            data.items.map((el) => {
                if (el.skin) {
                    el.skin = self.host + '/v1/file/' + el.skin + '?size=s'
                }
                el.requested = false;
                if (el.requests && el.requests.length) {
                    el.requests.forEach((req) => {
                        if (req.user_id == self.user.id) {
                            el.requestId = req.id;
                            el.requested = true;
                            if (req.status === 0) {
                                el.requestStatus = 'rejected';
                            }
                            if (req.status === 1) {
                                el.requestStatus = 'accepted';
                            }
                        }
                    })
                }
            });
            self.events = data.items;
            console.log('Prepared events', data.items);
            if (cb) cb();
        }, errorCb)
    },
    requestEvent(eventId) {
        let self = this;
        this.apiRequest('/request', {
            "Request": {
                "event_id": eventId
            }
        }, (data) => {
            self.showConfirmation("Заявка отправлена");
            self.getEvents();
        }, (errors) => {
            console.log(errors);
            if (errors.data && errors.data.length) {
                self.showErrorMessage(errors.data)
            }
        }, 'post');
    },
    rejectRequest(reqId) {
        let self = this;
        this.apiRequest('/request/' + reqId, {}, (data) => {
            self.getEvents();
        }, (errors) => {
        }, 'delete');
    },
    setRequestReward(requestId, position) {
        let self = this;
        self.loading = true;
        this.apiRequest('/request/set-reward/'+requestId, {
            reward: position
        }, (data) => {
            self.getEvents();
            self.loading = false;
        }, (errors) => {
            self.loading = false;
        }, 'put');
    },
    acceptRequestByTeacher(reqId) {
        let self = this;
        this.apiRequest('/request/approve', {
            id: reqId
        }, (data) => {
            if (data.data && data.data.event_id) {
                self.showErrorMessage(data.data)
            }
            self.getEvents();
        }, (errors) => {
        }, 'put');
    },

    rejectRequestByTeacher(reqId) {
        let self = this;
        this.apiRequest('/request/reject', {
            id: reqId
        }, (data) => {
            if (data.data && data.data.event_id) {
                self.showErrorMessage(data.data)
            }
            self.getEvents();
        }, (errors) => {
        }, 'put');
    },
    getMessages(cb, errorCb) {
        let self = this;
        let messages = {}

        if (this.getMessagesTimeout) {
            clearTimeout(this.getMessagesTimeout);
        }
        this.apiRequest('/message/new-count', null, (data) => {
            console.log('New messages count', data);
            self.newMessagesCount = data.count;
        });
        this.apiRequest('/message/show-chat', null, (data) => {

            console.log('Received messages', data);
            let topicsSeen = [];
            data.forEach((el) => {
                let userId = el.author_id;
                if (el.recipient_id != self.user.id) {
                    userId = el.recipient_id;
                }
                if (!messages[userId]) {
                    messages[userId] = [];
                }
                if (!topicsSeen[userId]) {
                    topicsSeen[userId] = 0;
                }
                if (!el.seen) {
                    topicsSeen[userId]++
                }
                messages[userId].push(el);
            });

            console.log('Prepared messages', messages);
            self.topicsSeen = topicsSeen;
            self.messages = messages;
            if (this.getMessagesTimeout) {
                clearTimeout(this.getMessagesTimeout);
            }
            this.getMessagesTimeout = setTimeout(() => self.getMessages(cb, errorCb), 15000);
            if (cb) cb();
        }, function () {
            self.messages = [];
            if (cb) cb();
        })
    },
    markMessagesAsRead(messages) {
        console.log('messages', messages);
        if (messages && messages.length) {
            this.apiRequest('/message/seen', {
                id: messages.map((m) => m.id)
            }, (data) => {
            }, () => {
            }, 'post');
        }
    },
    sendMessage(topicId) {
        let self = this;
        let message = this.draftMessages[topicId];
        if (!message) {
            return;
        }
        this.messageSending = true;
        this.apiRequest('/message', {
            "Message": {
                "recipient_id": topicId,
                "message": message,
                "files": self.messageAttachements
            }
        }, (data) => {
            self.messageAttachements = [];
            this.draftMessages[topicId] = '';
            self.getMessages(()=>{
                self.markMessagesAsRead(self.messages[topicId])
            });


            self.messageSending = false;
        }, (errors) => {
            self.messageSending = false;
        }, 'post');
    },
    getAchievements(cb, errorCb) {
        let self = this;
        this.apiRequest('/achievement', null, (data) => {
            console.log('Received achievements', data);

            data.map((el) => {
                el.preview = self.host + '/v1/file/' + el.file_id;
            });
            self.achievements = data;
            if (cb) cb();
        }, function () {
            self.achievements = [];
            if (cb) cb();
        })
    },

    getAchievementTypes(cb, errorCb) {
        let self = this;
        this.apiRequest('/achievement-type', null, (data) => {
            console.log('Received achievement types', data);
            self.achievementTypes = data;
            if (cb) cb();
        }, function () {
            self.achievementTypes = [];
            if (cb) cb();
        })
    },
    getCats(cb, errorCb) {
        let self = this;
        this.apiRequest('/user/cat', null, (data) => {
            console.log('Received categories', data);
            self.cats = data;
            if (cb) cb();
        }, function () {
            self.cats = [];
            if (cb) cb();
        })
    },
    getDocs(cb, errorCb) {
        let self = this;
        this.apiRequest('/user-file', null, (data) => {
            console.log('Received docs', data);
            data.map((el) => {
                el.preview = self.host + '/v1/file/' + el.file_id;
            });
            self.docs = data;
            if (cb) cb();
        }, function () {
            self.docs = [];
            if (cb) cb();
        })
    },
    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            if (cb) cb(reader.result, file.name);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    },
    loadData(callback) {
        let self = this;
        this.getUser((user) => {
            this.getNotifications(() => {
                this.getOrganizations(() => {
                    this.getDirections(() => {
                        this.getLevels(() => {
                            this.getTypes(() => {
                                this.getEvents(() => {
                                    this.getAchievements(() => {
                                    this.getAchievementTypes(() => {
                                        this.getTests(() => {
                                            this.getCats(() => {
                                                this.getDocs(() => {
                                                    this.getMessages(() => {
                                                        self.isAuthenticated = true;
                                                        self.isInitialized = true;
                                                        if (typeof callback == 'function') {
                                                            callback();
                                                        }
                                                    })
                                                })
                                            })
                                        })
                                        })
                                    })
                                })
                            })
                        })
                    })
                })
            })
        }, (error) => {
            window.localStorage.clear();
            window.sessionStorage.clear();
            self.isAuthenticated = false;
            self.isInitialized = true;
        })
    },
    init() {
        let self = this;
        //let href = window.location.href;
        self.isInitialized = false;
        if (window.localStorage.getItem('access_token') || sessionStorage.getItem('access_token')) {
            if (window.localStorage.getItem('access_token')) {
                this.accessToken = window.localStorage.getItem('access_token');
                this.user.id = window.localStorage.getItem('user_id');
            }
            if (window.sessionStorage.getItem('access_token')) {
                this.accessToken = window.sessionStorage.getItem('access_token');
                this.user.id = window.sessionStorage.getItem('user_id');
            }
            self.loadData();
        } else {
            self.isAuthenticated = false;
            self.isInitialized = true;
        }
    }
};