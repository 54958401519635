import React from "react";
import {Observer} from 'mobx-react';
import {Context} from "../utils/Context";
import {
    Link
} from "react-router-dom";

import Select from 'react-select';
import AsyncSelect from 'react-select/async';

import dayjs from 'dayjs';

export default function ({children, ...rest}) {
    return <Context.Consumer>
        {(store) => (
            <Observer>
                {() => {
                    let tests = store.tests.filter((test)=>{
                        if (store.testsType == 'old'){
                            return (test.result&&test.result.score)
                        }
                        if (store.testsType == 'all'){
                            return !test.result
                        }
                    }).map((test) => {
                            return (test.type == 3) ?
                                <a href={test.description} target="_blank" key={"test-" + test.id} className="tests-item">
                                    <h4 className="tests-item-header">{test.name}</h4>
                                    {test.file_id ? <div style={{
                                        backgroundImage: `url(${store.host}/v1/file/${test.file_id})`,
                                        margin:'10px 0',
                                        backgroundSize: 'cover',
                                        width: '100px',
                                        height: '100px'
                                    }}></div> : null}
                                    <p className="tests-item-description">
                                        {test.description}
                                    </p>
                                </a> : (test.type == 2) ? <a href={store.host + '/v1/file/' + test.file_id} target="_blank"
                                                             key={"test-" + test.id} className="tests-item">
                                    <h4 className="tests-item-header">{test.name}</h4>
                                    <p className="tests-item-description">
                                        {test.description}
                                    </p>
                                </a> : <Link to={store.testsType == 'old'?null:((test.type == 1) ? "/test/" + test.id : '')} key={"test-" + test.id}
                                             style={{cursor:(store.testsType == 'old'?'default':'pointer')}}className="tests-item">
                                    <h4 className="tests-item-header">{test.name}</h4>
                                    {test.file_id ? <div style={{
                                        backgroundImage: `url(${store.host}/v1/file/${test.file_id})`,
                                        backgroundSize: 'cover',
                                        margin:'10px 0',
                                        width: '100px',
                                        height: '100px'
                                    }}></div> : null}
                                    <p className="tests-item-description">
                                        {test.description}
                                        {test.result?<span style={{display:'block',marginTop:'10px'}}>
                                            <b>Результат последнего прохождения:</b> {test.result.score} {test.result.score % 10 == 1 ?
                                            'балл' :
                                            test.result.score % 10 > 1 && test.result.score % 10 < 5 ?
                                                'балла' :
                                                'баллов'}{test.result.conclusion?'. '+test.result.conclusion:null}
                                        </span>:null}
                                    </p>
                                </Link>
                        }
                    );

                    return <div className="">
                        <h1 className="mobile-padding">Тесты</h1>
                        <div className="mobile-padding tests-menu">
                            <a href="#" onClick={() => {
                                store.testsType = 'all'
                            }} className={store.testsType == 'all' ? 'active' : null}>Все тесты</a>
                            <a href="#" onClick={() => {
                                store.testsType = 'old'
                            }} className={store.testsType == 'old' ? 'active' : null}>Пройденные тесты</a>
                        </div>
                        <div className="tests-list">
                            {tests.length ? tests : <div>Тестов не найдено</div>}
                        </div>
                    </div>
                }}
            </Observer>
        )}
    </Context.Consumer>;
}