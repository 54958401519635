import React from "react";
import {Observer} from 'mobx-react';
import {Context} from "../utils/Context";
import {
    Link
} from "react-router-dom";
import {FileInput, PreviewImg} from "../utils/FileUpload";

import Select from 'react-select';
import AsyncSelect from 'react-select/async';


import dayjs from 'dayjs';


export default function ({children, ...rest}) {
    return <Context.Consumer>
        {(store) => (
            <Observer>
                {() => {

                    let portfolio = store.docs.map((el) => {
                        return <div onClick={() => {
                            store.currentDoc = el
                        }} key={"event-" + el.id} className="portfolio-item">
                            <div className="portfolio-img" style={{backgroundImage: `url(${el.preview})`}}></div>
                            <div className="portfolio-content">
                                <a href={el.preview} target="_blank">Скачать</a>
                                <div className="portfolio-date">{el.description}</div>
                            </div>
                        </div>
                    });


                    return <div className="mobile-padding">
                        <h1>Документы</h1>
                        <div className="portfolio-top-row row">
                            <a href="#" onClick={() => store.addDocModalShown=true} className="btn dark">Добавить документ</a>
                        </div>
                        <div className="portfolio-list">
                            {portfolio.length ? portfolio : <div>Вы еще не добавили документов</div>}
                        </div>
                        {store.addDocModalShown ? <div className="modal">
                            <div className="modal-block">
                                <a href="javascript:void(0)" onClick={() => store.addDocModalShown = false}
                                   className="modal-cross"></a>
                                <h1>Добавить документ</h1>

                                {(store.newDocError) ? <ul className="row errors-block">
                                    {store.newDocError ? <li>{store.newDocError}</li> : null}
                                    {store.serverError ? <li>{store.serverError.toString()}</li> : null}
                                </ul> : null}

                                <div className="input-row">
                                    <label>Название документа:</label>
                                    <input type="text" className="full"
                                           value={store.newDocTitle ? store.newDocTitle : ''}
                                           onChange={(e) => {
                                               store.newDocTitle = e.target.value
                                           }} placeholder="Название документа"/>
                                </div>

                                <div className="row">
                                    <FileInput label="Выбрать файл" onChange={(files) => {
                                        if (files.length) {
                                            store.getBase64(files[0], (value, filename) => {
                                                store.newDocFile = {
                                                    name: filename,
                                                    content: value.split('base64,')[1]
                                                }
                                            })
                                        }
                                    }}/>
                                </div>
                                <div className="row">
                                    <button className="btn btn-success dark full" onClick={() => {
                                        store.saveDoc();
                                    }}>Сохранить
                                    </button>
                                </div>
                            </div>
                        </div> : null}
                    </div>
                }}
            </Observer>
        )}
    </Context.Consumer>;
}