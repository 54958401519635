import {Redirect, useHistory, useLocation} from "react-router-dom";
import React from "react";
import {Observer} from 'mobx-react';
import {Context} from "../utils/Context";

import {
    Link
} from "react-router-dom";

export default class SignUp extends React.Component {
  render() {
    return <Context.Consumer>{(store) =>
        <Observer>{() => {

            let history = useHistory();
            let location = useLocation();
            let {from} = location.state || {from: {pathname: "/"}};
            return (
                store.isAuthenticated ? <Redirect
                    to={{
                      pathname: "/",
                      state: { from: location }
                    }}
                  /> : <div>
                    <header>
                        <div className="wrapper">
                            <a href="#" className="logo">Центр одаренных детей</a>
                        </div>
                    </header>
                    <div className="content main centered">
                        <div className="single column">
                            <h1 style={{
                                flexDirection: 'row',
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                                <div>Регистрация ученика</div>
                                <Link style={{fontSize:'14px'}} to="/login">Вход в систему</Link>
                            </h1>
                            <div className="column">
                                {(store.signupNameError || store.signupTermsError ||store.serverError || store.signupPasswordError || store.signupEmailError) ? <ul className="row errors-block">
                                    {store.signupNameError?<li>{store.signupNameError}</li>:null}
                                    {store.signupEmailError?<li>{store.signupEmailError}</li>:null}
                                    {store.signupPasswordError?<li>{store.signupPasswordError}</li>:null}
                                    {store.signupTermsError?<li>{store.signupTermsError}</li>:null}
                                    {store.serverError?<li>{store.serverError.toString()}</li>:null}
                                </ul> : null}
                                <div className="input-row list">
                                    <label htmlFor="signup-username">Ф.И.О.:</label>
                                    <input id="signup-username"
                                           className={'full ' + (store.signupNameError ? "error" : "")}
                                           type="text" value={store.signupName} onChange={(e) => {
                                        store.signupName = e.target.value
                                    }}/>
                                </div>
                                <div className="input-row list">
                                    <label htmlFor="signup-email">Логин (эл. почта):</label>
                                    <input id="signup-email"
                                           className={'full ' + (store.signupEmailError ? "error" : "")}
                                           type="email" value={store.signupEmail} onChange={(e) => {
                                        store.signupEmail = e.target.value
                                    }}/>
                                </div>
                                <div className="input-row list">
                                    <label htmlFor="signup-password">Пароль:</label>
                                    <input id="signup-password"
                                           className={'full ' + (store.signupPasswordError ? "error" : "")}
                                           type="password" value={store.signupPassword} onChange={(e) => {
                                        store.signupPassword = e.target.value
                                    }}/>
                                </div>
                                <div className="input-row list">
                                    <label htmlFor="signup-terms">
                                        <input id="signup-terms"
                                           type="checkbox" checked={store.signupTerms} onChange={(e) => {
                                        store.signupTerms = e.target.checked
                                    }}/> Я даю согласие на обработку моих персональных данных
                                    </label>

                                </div>

                                <div className="row">
                                    <button disabled={store.signupLoading} onClick={() => {
                                        store.signup()
                                    }} className="btn dark large">Регистрация</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }}</Observer>}
        </Context.Consumer>
    }
}