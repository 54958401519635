import React from "react";
import {Observer} from 'mobx-react';
import {Context} from "../utils/Context";
import {
    Link
} from "react-router-dom";

import Select from 'react-select';
import AsyncSelect from 'react-select/async';

import dayjs from 'dayjs';

export default function ({children, ...rest}) {
    return <Context.Consumer>
        {(store) => (
            <Observer>
                {() => {

                    let parts = window.location.pathname.split('/');
                    let testId = parts[parts.length - 1];
                    let test = null;

                    store.tests.forEach((el) => {
                        if (testId == el.id) {
                            test = el;
                        }
                    });
                    if (!test) {
                        return <div className="messages-header">
                            <h1><Link to="/tests">
                                <div className="back"></div>
                            </Link>Тест не найден
                            </h1>
                        </div>
                    }
                    return <div className="questions-block">
                        <div className="questions">
                            {store.currentTestQuestion == 0 ? <div className="question-item">
                                <Link to="/tests" className="question-cross"></Link>
                                <h2>{test.name}</h2>
                                <div className="question-description">
                                    <div>{test.description}</div>
                                    <div>{test.question.length} {
                                        test.question.length % 10 == 1 ?
                                            'вопрос' :
                                            test.question.length % 10 > 1 && test.question.length % 10 < 5 ?
                                                'вопроса' :
                                                'вопросов'}</div>
                                </div>
                                {test.file_id ? <div style={{
                                        backgroundImage: `url(${store.host}/v1/file/${test.file_id})`,
                                        backgroundSize: 'cover',
                                        alignSelf:'center',
                                    margin: '30px 0',
                                        width: '100px',
                                        height: '100px'
                                    }}></div> : null}
                                <div style={{flex: 1}}></div>
                                <button className="btn dark" onClick={() => {
                                    store.currentTestQuestion = 1;
                                }}>Начать тест
                                </button>
                            </div> : null}
                            {store.currentTestQuestion == -1 ? <div className="question-item">
                                <h2>Результат теста {test.name}</h2>
                                <div className="question-description">
                                    <h3>Ваш результат: {store.testScore} {store.testScore % 10 == 1 ?
                                        'балл' :
                                        store.testScore % 10 > 1 && store.testScore % 10 < 5 ?
                                            'балла' :
                                            'баллов'}</h3>
                                </div>
                                {store.testResult ? <p>{store.testResult}</p> : null}
                                <div style={{flex: 1}}></div>
                                <Link to="/tests" className="btn dark" onClick={() => {
                                    store.answers = [];
                                    store.currentTestQuestion = 0;
                                    store.currentAnswer = null;
                                    store.getTests();
                                }}>Завершить тест</Link>
                            </div> : null}
                            {test.question.map((question, key) => {
                                return (store.currentTestQuestion == key + 1) ?
                                    <div key={"q-" + store.currentTestQuestion + '-' + key} className="question-item">
                                        <div className="question-description">
                                            <div>Вопрос {key + 1} из {test.question.length}</div>
                                        </div>
                                        <h2>{question.text}</h2>
                                        {question.file_id ? <div style={{
                                            backgroundImage: `url(${store.host}/v1/file/${question.file_id})`,
                                            backgroundSize: 'cover',
                                            width: '150px',
                                            height: '150px'
                                        }}></div> : null}
                                        <div className="answers-block">
                                            {question.type == 2 ? <label className="answer input-row"
                                                                         key={"q-" + store.currentTestQuestion + '-' + key + '-1'}>
                                                Ответ:
                                                <input style={{
                                                    width: '100%',
                                                    marginTop: '10px'
                                                }} onChange={(e) => {
                                                    store.currentAnswer = e.target.value
                                                }} type="text" value={store.currentAnswer ? store.currentAnswer : ''}
                                                       name={"q-" + store.currentTestQuestion + '-' + key + '-1'}/>
                                            </label> : question.type == 3 ?question.answers.map((answer, aKey) => {
                                                return <label className="answer"
                                                              key={"q-" + store.currentTestQuestion + '-' + key + '-' + aKey}>
                                                    <input style={{margin:0, marginRight:'10px'}} onChange={(e) => {
                                                        if (!store.currentAnswer) {
                                                            store.currentAnswer = []
                                                        }
                                                        store.currentAnswer.push(e.target.value);
                                                    }} type="checkbox"
                                                           checked={store.currentAnswer&&store.currentAnswer.indexOf(answer.id)>-1?true:null}
                                                           value={answer.id}
                                                           name={"q-" + store.currentTestQuestion + '-' + key}/>
                                                    {answer.text}

                                                    {answer.file_id ? <div style={{
                                                        backgroundImage: `url(${store.host}/v1/file/${answer.file_id})`,
                                                        backgroundSize: 'cover',
                                                        width: '100px',
                                                        height: '100px'
                                                    }}></div> : null}
                                                </label>
                                            }):question.answers.map((answer, aKey) => {
                                                return <label className="answer"
                                                              key={"q-" + store.currentTestQuestion + '-' + key + '-' + aKey}>
                                                    <input onChange={(e) => {
                                                        store.currentAnswer = e.target.value
                                                    }} type="radio" value={answer.id}
                                                           name={"q-" + store.currentTestQuestion + '-' + key}/>
                                                    {answer.text}

                                                    {answer.file_id ? <div style={{
                                                        backgroundImage: `url(${store.host}/v1/file/${answer.file_id})`,
                                                        backgroundSize: 'cover',
                                                        width: '100px',
                                                        height: '100px'
                                                    }}></div> : null}
                                                </label>
                                            })}
                                        </div>
                                        {key < test.question.length - 1 ?
                                            <button className="btn dark" onClick={() => {
                                                if (store.currentAnswer) {
                                                    store.answers.push({
                                                        questionId: question.id,
                                                        answerId: store.currentAnswer
                                                    })
                                                }
                                                store.currentAnswer = null;
                                                store.currentTestQuestion++;
                                            }}>Следующий вопрос</button> :
                                            <button className="btn dark" onClick={async () => {
                                                if (store.currentAnswer) {
                                                    store.answers.push({
                                                        questionId: question.id,
                                                        answerId: store.currentAnswer
                                                    })
                                                }
                                                let answers = [];
                                                store.answers.forEach((aItem) => {
                                                    let item = {}
                                                    item[aItem.questionId] = aItem.answerId;
                                                    answers.push(item)
                                                })
                                                await store.apiRequest('/test/check/' + test.id,
                                                    {"answers": answers},
                                                    (data) => {
                                                        store.testResult = data.text;
                                                        store.testScore = data.score;
                                                        store.currentTestQuestion = -1;
                                                    },
                                                    (errors) => {
                                                        console.log(errors)
                                                    },
                                                    'post');
                                            }}>Результат</button>
                                        }
                                    </div> : null
                            })}
                        </div>
                    </div>
                }}
            </Observer>
        )}
    </Context.Consumer>;
}