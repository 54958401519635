import React from "react";
import Layout from "./Layout";
import {Context} from "../utils/Context";
import {Observer} from 'mobx-react';

export default function ({children, hideSidebar, ...rest}) {
    return <Context.Consumer>
        {(store) => (
            <Observer>
                {() => {
                    if (hideSidebar){
                        store.showSidebar = false;
                    } else {
                        store.showSidebar = true;
                    }
                    return <Layout>{children}</Layout>
                }}
            </Observer>
        )}
    </Context.Consumer>;
}