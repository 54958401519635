import React from "react";
import {Observer} from 'mobx-react';
import {Context} from "../utils/Context";
import {
    useParams,
    Link
} from "react-router-dom";

export default function ({children, ...rest}) {
    return <Context.Consumer>
        {(store) => (
            <Observer>
                {() => {
                    let parts = window.location.pathname.split('/');
                    let topicId = parts[parts.length - 1];
                    let messages = [];
                    let author = 'Модератор';
                    let topic = store.messages[topicId];
                    if (topic && topic.length) {
                        author = topic[0].author;
                        if (topic[0].author_id == store.user.id) {
                            author = topic[0].recipient;
                        }
                        let previousDate = null;
                        topic.forEach((message, i) => {
                            let dateChanged = false;
                            if (previousDate != message.date.substr(0, 10)) {
                                dateChanged = true;
                                previousDate = message.date.substr(0, 10);
                            }
                            let type = 'from';
                            if (message.author_id != store.user.id) {
                                type = 'to'
                            }
                            messages.push(<div key={'message-' + i} className="messages-topic">
                                {dateChanged ? <div className="messages-topic-day">{previousDate}</div> : null}
                                <div className="messages-topic-item">
                                    <div className="messages-topic-avatar">{message.author[0]}</div>
                                    <div className="messages-topic-msg">
                                        <div className="messages-topic-header">
                                            <span>{message.author}</span>
                                            <div className="messages-topic-date">{message.date.substr(11)}</div>
                                        </div>
                                        <p>{message.message}</p>
                                        {message.files ?
                                            message.files.map((file) => <div key={'file-'+file.id}><a
                                                href={store.host + '/v1/file/' + file.id} target="_blank">Скачать
                                                файл {file.name}</a></div>)
                                            : null}
                                    </div>
                                </div>
                            </div>)
                        })
                    } else {
                        if (topicId>0){
                            author = store.currentRecepient;
                        }
                    }
                    return <div>
                        <div className="mobile-padding messages-header">
                            <h1><Link to="/messages">
                                <div className="back"></div>
                            </Link>{author}
                            </h1>
                        </div>
                        <div className="messages-container">
                            {messages.length ?
                                <div style={{paddingBottom:'200px'}}>
                                    {messages}
                                </div>
                                : <div className="mobile-padding" >Нет сообщений</div>}

                            <div className="messages-new-msg">
                                <div style={{
                                    display: 'flex',
                                    width: '100%',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>
                                    <label className="msg-attachment">
                                        <input
                                            multiple="multiple"
                                            style={{display: "none"}}
                                            type="file"
                                            accept="image/*"
                                            onChange={e => {
                                                if (e.target.files.length) {
                                                    Array.prototype.forEach.call(e.target.files, (file) => {
                                                        store.getBase64(file, (value, filename) => {
                                                            store.messageAttachements.push({
                                                                content: value.split('base64,')[1],
                                                                name: filename
                                                            });
                                                        })
                                                    });
                                                }
                                            }}
                                        />
                                    </label>
                                    <textarea
                                        disabled={store.messageSending}
                                        placeholder="Напишите сообщение..."
                                        value={store.draftMessages[topicId] ? store.draftMessages[topicId] : ''}
                                        onChange={(e) => {
                                            store.draftMessages[topicId] = e.target.value
                                        }}
                                        rows="1" onKeyUp={(event) => {
                                        if (event.keyCode == 13) {
                                            if (event.ctrlKey) {
                                                event.target.rows += 1;
                                            } else {
                                                store.sendMessage(topicId)
                                            }
                                        }
                                    }}></textarea>
                                    <button className="msg-send" onClick={() => store.sendMessage(topicId)}/>
                                </div>
                                {store.messageAttachements.length ?
                                    <div className="message-attachments">Приложен{store.messageAttachements.length>1 ? 'ы ' : ' '}
                                        файл{store.messageAttachements.length>1 ? 'ы' : ''}:
                                        {store.messageAttachements.map((file, i) => <div key={'attachment-' + i}>
                                            {file.name}
                                            <button onClick={() => {
                                                store.messageAttachements.splice(i, 1)
                                            }}>Удалить
                                            </button>
                                        </div>)}</div> : null}
                            </div>
                        </div>
                    </div>
                }}
            </Observer>
        )}
    </Context.Consumer>;
}