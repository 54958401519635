import {Redirect, useHistory, useLocation} from "react-router-dom";
import React from "react";
import {Observer} from 'mobx-react';
import {Context} from "../utils/Context";

import {
    Link
} from "react-router-dom";

export default class Confirm extends React.Component {

    componentDidMount() {
        if (this.store && this.store.findGetParameter('id') && this.store.findGetParameter('token')) {
            let self = this;
            this.store.confirm(this.store.findGetParameter('id'), this.store.findGetParameter('token'), () => {
                self.store.emailConfirmed = true;
            }, (error) => {
                self.store.emailConfirmationError = error;
            });
        }
    }

    render() {
        let self = this;
        return <Context.Consumer>{(store) =>
            <Observer>{() => {
                self.store = store;
                let history = useHistory();
                let location = useLocation();
                let {from} = location.state || {from: {pathname: "/"}};
                return (
                    (store.isAuthenticated || store.emailConfirmed) ? <Redirect
                        to={{
                            pathname: "/",
                            state: {from: location}
                        }}
                    /> : <div>
                        <header>
                            <div className="wrapper">
                                <a href="#" className="logo">Центр одаренных детей</a>
                            </div>
                        </header>
                        {self.store.emailConfirmationError?<div className="content main centered">
                            <h2>{self.store.emailConfirmationError}</h2>
                        </div>:null}
                    </div>
                )
            }}</Observer>}
        </Context.Consumer>
    }
}