import React from "react";
import {Observer} from 'mobx-react';
import {Context} from "../utils/Context";
import {
    Link
} from "react-router-dom";

import Select from 'react-select';
import AsyncSelect from 'react-select/async';

export default function ({children, ...rest}) {
    return <Context.Consumer>
        {(store) => (
            <Observer>
                {() => (
                    <div className="mobile-padding">
                        <div className="dashboard-levels">
                            <h6>Ваш уровень:</h6>

                            <div className="dashboard-levels-row">
                                <div className={"dashboard-level level-1 " + (store.user.status == 1 ? 'active' : '')}
                                     title="Стажёр"></div>
                                <div className={"dashboard-level level-2 " + (store.user.status == 2 ? 'active' : '')}
                                     title="Юниор"></div>
                                <div className={"dashboard-level level-3 " + (store.user.status == 3 ? 'active' : '')}
                                     title="Профи"></div>
                                <div className={"dashboard-level level-4 " + (store.user.status == 4 ? 'active' : '')}
                                     title="Мастер"></div>
                            </div>
                        </div>
                        {store.notifications.length ? <div>
                            <h2>Лента событий</h2>
                            <div className="dashboard-events">
                                {store.notifications.map((item) => <div className="dashboard-event">
                                    <div className="dashboard-event-date">{item.date}</div>
                                    <div className="dashboard-event-msg">
                                        <p dangerouslySetInnerHTML={{__html: item.text}}></p>
                                    </div>
                                </div>)}
                            </div>
                        </div> : null}
                    </div>
                )}
            </Observer>
        )}
    </Context.Consumer>;
}