import React from "react";

const FileInput = ({value, onChange, label, style, ...rest}) => {
    return <div>
        <label style={style} className="btn light">
            {label?label:'Загрузить изображение'}
            <input
                {...rest}
                style={{display: "none"}}
                type="file"
                accept="image/*"
                onChange={e => {
                    onChange([...e.target.files]);
                }}
            />
        </label>
    </div>
};
const PreviewImg = ({value}) => {
    let src = '';
    let content = value;
    if (value.content){
        content = value.content;
    }
    if (content.indexOf('data:') === 0 || content.indexOf('http') === 0 || content.indexOf('/file/') > 0) {
        src = content+'?size=s';
    } else {
        src = 'data:image/jpeg;base64,' + content;
    }
    return <div style={{
        backgroundImage: 'url(' + src + ')',
        width: 'auto',
        height: '200px',
        backgroundSize: 'contain',
        margin: '0 20px 20px 0'
    }}></div>
};
export {
    FileInput,
    PreviewImg
}