import {Redirect, useHistory, useLocation} from "react-router-dom";
import React from "react";
import {Observer} from 'mobx-react';
import {Context} from "../utils/Context";

import FacebookLogin from 'react-facebook-login';
import VK, {Auth} from 'react-vk';
import {
    Link
} from "react-router-dom";

class LoginPage extends React.Component {
  render() {
    return <Context.Consumer>{(store) =>
        <Observer>{() => {
            return (
                <div>
                    <header>
                        <div className="wrapper">
                            <a href="#" className="logo">Центр одаренных детей</a>
                        </div>
                    </header>
                    <div className="content main centered">
                        <div className="single column">
                            <h1 style={{
                                flexDirection: 'row',
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                                <div>Вход в систему</div>
                                <Link style={{fontSize:'14px'}} to="/register">Регистрация</Link>
                            </h1>
                            <div className="column">
                                {(store.authLoginError||store.serverError||store.authPasswordError) ? <ul className="row errors-block">
                                    {store.authLoginError?<li>{store.authLoginError}</li>:null}
                                    {store.authPasswordError?<li>{store.authPasswordError}</li>:null}
                                    {store.serverError?<li>{store.serverError}</li>:null}
                                </ul> : null}
                                {store.authMessage?<h2 style={{maxWidth: '500px'}}>{store.authMessage}</h2>:null}
                                {store.passwordChanged?<h2 style={{maxWidth: '500px'}}>На электронную почту отправлено письмо с инструкциями по смене пароля (письмо может попасть в папку Спам)</h2>:null}
                                <div className="input-row list">
                                    <label htmlFor="login-username">Логин (эл. почта):</label>
                                    <input id="login-username"
                                           className={'full ' + (store.authLoginError ? "error" : "")}
                                           type="email" value={store.authLogin} onChange={(e) => {
                                        store.authLogin = e.target.value
                                    }}/>
                                </div>
                                <div className="input-row list">
                                    <label htmlFor="login-password">Пароль:</label>
                                    <input id="login-password"
                                           className={'full ' + (store.authPasswordError ? "error" : "")}
                                           type="password" value={store.authPassword} onChange={(e) => {
                                        store.authPassword = e.target.value
                                    }}/>
                                </div>
                                <div className="row">
                                    <label style={{flex:1}} htmlFor="login-remember">
                                        <input id="login-remember" onClick={(e)=>{
                                            store.authStoreAuth = e.target.checked
                                        }} value={store.authStoreAuth} type="checkbox"/>
                                        Запомнить меня
                                    </label>
                                    <div><Link to="/forgot">Забыли пароль?</Link></div>
                                </div>

                                <div className="row">
                                    <button disabled={store.authLoading} onClick={() => {
                                        store.login()
                                    }} className="btn dark large">Войти</button>

                                </div>
                                {!store.showLoginButtons?<div className="row login-button-row">
                                    <button className="btn border large" onClick={()=>{
                                        store.showLoginButtons = true
                                    }}>Войти через социальные сети</button>
                                </div>:<div>
                                    <div className="row login-button-row">
                                        <FacebookLogin
                                            appId="532636637633088"
                                            autoLoad={false}
                                            fields="name,email,picture"
                                            cssClass="btn border large"
                                            textButton ="Войти через Facebook"
                                            callback={(response) => {
                                                if (response.accessToken) {
                                                    store.apiRequest('/user/login-by-social', {
                                                        "LoginBySocial": {
                                                            "social": "facebook",
                                                            "token": response.accessToken
                                                        }
                                                    }, (data) => {
                                                        console.log('Received', data);
                                                        store.storeTokens(data);
                                                    }, (errors) => {
                                                        console.log('fb errrors', errors);
                                                        store.showErrorMessage("Пользователь не найден");
                                                    }, 'post');
                                                } else {
                                                    store.showErrorMessage("Авторизация не удалась");
                                                }
                                            }} />
                                    </div>
                                    <div className="row login-button-row">
                                        <VK apiId="7363676">
                                            <Auth width={'100%'} options={{
                                                onAuth: user => {
                                                    console.log(user);
                                                    if (user) {
                                                        store.apiRequest('/user/login-by-social', {
                                                            "LoginBySocial": {
                                                                "social": "vk",
                                                                "token": user.hash
                                                            }
                                                        }, (data) => {
                                                            console.log('Received', data);
                                                            store.storeTokens(data);
                                                        }, (errors) => {
                                                            console.log('vk errrors', errors);
                                                            store.showErrorMessage("Пользователь не найден");
                                                        }, 'post');
                                                    } else {
                                                        store.showErrorMessage("Авторизация не удалась");
                                                    }

                                                },
                                            }}/>
                                        </VK>
                                    </div>
                                </div>}

                            </div>
                        </div>
                    </div>
                </div>
            )
        }}</Observer>}
        </Context.Consumer>
    }
}
export default LoginPage