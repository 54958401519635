import React from "react";
import {Observer} from 'mobx-react';
import {Context} from "../utils/Context";
import {
    Link
} from "react-router-dom";

import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import {FileInput, PreviewImg} from "../utils/FileUpload";
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import dayjs from 'dayjs';

export default function ({children, ...rest}) {
    return <Context.Consumer>
        {(store) => (
            <Observer>
                {() => {
                    if (store.role == 'student') {
                        window.location.href = "/events";
                        return null;
                    }

                    let event = null;

                    let parts = window.location.pathname.split('/');
                    let eventId = parts[parts.length - 1];

                    store.events.forEach((el) => {
                        if (eventId == el.id) {
                            event = el;
                        }
                    });

                    function renderParticipants(requests) {
                        return <div><table className="requests-table">
                            <thead>
                            <tr style={{
                                background: '#FEFEFE'
                            }}>
                                <td></td>
                                <td>#</td>
                                <td>Имя</td>
                                <td>Адрес</td>
                                <td>Школа</td>
                                <td style={{width: '20%'}}>{event.event_status != 3 ? 'Заявка' : 'Результат'}</td>

                                {event.event_status != 3 ? null : <td style={{width: '10%'}}></td>}
                            </tr>
                            </thead>
                            <tbody>
                            {requests.map((el) => {
                                let status = null;
                                let reward = null;
                                let file = null;
                                let description = null;
                                event.requests.forEach((req) => {
                                    if (req.id == el.requestId) {
                                        status = req.status;
                                        reward = req.reward;
                                        file = req.file_id;
                                        description = req.description;
                                    }
                                });
                                return <tr key={'req-' + el.id}>
                                    <td valign="top">
                                        {(event.event_status == 3 && status == 1)?<input style={{margin:0}} type="checkbox" checked={store.currentSelectedUsers.indexOf(el.requestId)>-1} onChange={(e)=>{
                                        if (e.target.checked) {
                                            store.currentSelectedUsers.push(el.requestId);
                                        } else {
                                            store.currentSelectedUsers.splice(store.currentSelectedUsers.indexOf(el.requestId), 1);
                                        }
                                    }}/>:null}
                                    </td>
                                    <td valign="top">{el.id}</td>
                                    <td style={{display:'flex'}}><Link to={"/chat/" + el.id} onClick={()=>store.currentRecepient = el.name} className="send-icon"></Link>
                                        <Link to={"/user/" + el.id}>{el.name}</Link>
                                    </td>
                                    <td valign="top">{el.address}</td>
                                    <td valign="top">{el.organization}</td>
                                    <td>
                                        {
                                            event.event_status == 3 ? ((status == 1)?<div>
                                                <div>
                                                    <select value={reward?reward:''} onChange={(e)=>{
                                                        let position = e.target.value;
                                                        store.setRequestReward(el.requestId, position);
                                                    }}>
                                                        <option value="0">нет</option>
                                                        <option value="1">1 место</option>
                                                        <option value="2">2 место</option>
                                                        <option value="3">3 место</option>
                                                    </select>
                                                </div>
                                            </div>:<div>Заявка отклонена</div>) : <div>
                                                {file?<div style={{margin: '0 10px 10px 0'}}>
                                                    <a href={store.host + '/v1/file/' + file} target="_blank">Скачать приложенный файл</a>
                                                    {description?<div>Комментарий: {description}</div>:null}
                                                </div>:null}
                                                <div style={{display: 'flex'}}>
                                                {status !== 1 ? <a href="#" onClick={() => {
                                                    store.acceptRequestByTeacher(el.requestId)
                                                }} className="btn dark">Принять заявку</a> : null}
                                                {status !== 0 ? <a href="#" onClick={() => {
                                                    store.rejectRequestByTeacher(el.requestId)
                                                }} className="btn light" style={{marginLeft: '10px'}}>Отклонить</a> : null}
                                                </div>
                                            </div>
                                        }
                                        <div>

                                        {event.event_status != 3 ? null :
                                            ((status == 1)?<FileInput style={{margin:0, marginTop:'10px'}} label="Загрузить грамоту" onChange={(files) => {
                                                if (files.length) {
                                                    store.getBase64(files[0], (value, filename) => {
                                                        let rewardImg = {
                                                            name: filename,
                                                            content: value.split('base64,')[1]
                                                        };
                                                        store.apiRequest('/request/set-reward-image/'+el.requestId, rewardImg, (data) => {
                                                            store.getEvents()
                                                        }, (errors) => {
                                                            console.error(errors);
                                                        }, 'post');
                                                    })
                                                }
                                            }}/>:null)
                                        }
                                        </div>
                                    </td>
                                </tr>
                            })}
                            </tbody>
                        </table></div>
                    }

                    let numberOfParticipants = 0;
                    event.requests.forEach((el) => {
                        if (store.currentEventId != eventId) {
                            store.apiRequest("/user/" + el.user_id, {}, function (user) {
                                user.requestId = el.id;
                                store.currentRequests.push(user);
                            });
                        }
                        if (el.status) {
                            numberOfParticipants++
                        }

                    });
                    if (store.currentEventId != eventId) {
                        store.currentRequests = [];
                        store.currentSelectedUsers = [];
                        store.currentEventId = eventId;
                    }

                    return <div className="events-list mobile-padding">
                        <h1><Link to="/events">
                            <div className="back"></div>
                        </Link>{event.short_name}</h1>
                        <div className="events-menu">
                            <div className="events-item-date">{event.start.substr(0,10)}</div>
                            <div className="events-item-status"
                                 style={{color: '#6FA048'}}>{event.event_status == 2 ?
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <span>Опубликовано</span>
                                    <button className="btn border" style={{marginLeft: '10px'}} onClick={() => {
                                        store.completeEvent(event.id)
                                    }}>Завершить
                                    </button>
                                </div>
                                : event.event_status == 1 ? 'Черновик' : 'Завершено'}
                            </div>
                            {event.max_participants ? <div
                                className="events-item-status">{numberOfParticipants}/{event.max_participants} участников
                            </div> : null}
                        </div>
                        <p className="event-description" dangerouslySetInnerHTML={{__html: event.description}}></p>
                        <Link to={"/events/edit/" + event.id} onClick={() => {
                            store.resetEvent()
                        }} style={{
                            marginTop: '20px',
                            fontWeight: 'bold'
                        }}>Редактировать</Link>
                        <h2>Участники</h2>
                        {store.currentSelectedUsers.length?<div>
                            <div style={{
                                margin:'10px 0'
                            }}>Выставить результат для выбранных пользователей:</div>
                            <div style={{
                                display:'flex',
                                flexDirection:'row',
                                alignItems:'center'
                            }}>
                                <select style={{
                                    margin:'5px 0'
                                }} onChange={(e)=>{
                                    store.currentMultiplePosition =  e.target.value;
                                }}>
                                    <option value="0">нет</option>
                                    <option value="1">1 место</option>
                                    <option value="2">2 место</option>
                                    <option value="3">3 место</option>
                                </select>
                                <button className="btn light" style={{
                                    height: '30px',
                                    padding: '0 30px',
                                    marginLeft: '10px'
                                }} onClick={()=>{
                                    store.currentSelectedUsers.forEach((reqId)=>{
                                        store.setRequestReward(reqId, store.currentMultiplePosition);
                                    })
                                }}>Сохранить</button>
                            </div>
                        </div>:null}
                        {event.requests.length ? renderParticipants(store.currentRequests) :
                            <div>Заявок на мероприятие не поступило</div>}
                    </div>
                }}
            </Observer>
        )}
    </Context.Consumer>;
}