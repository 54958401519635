import React from "react";
import {useLocalStore} from "mobx-react-lite";
import {
    Router,
    Switch,
    Route,
} from "react-router-dom";
import {Context} from "./utils/Context";
import Store from "./Store";
import history from "./utils/History";

import LoginPage from "./components/LoginPage";
import LogoutPage from "./components/LogoutPage";
import SignUp from "./components/SignUp";
import ForgotPassword from "./components/ForgotPassword";
import PrivateRoute from "./components/PrivateRoute";
import Profile from "./components/Profile";
import StudentProfile from "./components/StudentProfile";
import Confirm from "./components/Confirm";
import Dashboard from "./components/Dashboard";
import ChangePassword from "./components/ChangePassword";
import Messages from "./components/Messages";
import Chat from "./components/Chat";
import Documents from "./components/Documents";
import Tests from "./components/Tests";
import Test from "./components/Test";
import Portfolio from "./components/Portfolio";
import Events from "./components/Events";
import EventEdit from "./components/EventEdit";
import EventView from "./components/EventView";
import Loading from "./components/Loading";
import {Observer} from 'mobx-react';


export default function App() {
    const store = useLocalStore(() => (Store));
    store.init();
    return <Context.Provider value={store}>
        <Observer>{() =>
            (!store.isInitialized ?
                <Loading/> :
                !store.isAuthenticated ? <Router history={history}>
                    <Switch>
                        <Route path="/login">
                            <LoginPage/>
                        </Route>
                        <Route path="/register">
                            <SignUp/>
                        </Route>
                        <Route path="/forgot">
                            <ForgotPassword/>
                        </Route>
                        <Route path="/confirm">
                            <Confirm/>
                        </Route>
                        <Route path="/password-reset">
                            <ChangePassword/>
                        </Route>
                        <Route path="/">
                            <LoginPage/>
                        </Route>
                    </Switch>
                </Router> :
                <Router history={history}>
                    <Switch>
                        <PrivateRoute path="/logout">
                            <LogoutPage/>
                        </PrivateRoute>
                        <PrivateRoute path="/profile">
                            <Profile/>
                        </PrivateRoute>
                        <PrivateRoute path="/events/new">
                            <EventEdit/>
                        </PrivateRoute>
                        <PrivateRoute path="/events/edit/:eventId">
                            <EventEdit/>
                        </PrivateRoute>
                        <PrivateRoute path="/send-application/:eventId">
                            <Events/>
                        </PrivateRoute>
                        <PrivateRoute path="/events/:eventId">
                            <EventView/>
                        </PrivateRoute>
                        <PrivateRoute path="/events">
                            <Events/>
                        </PrivateRoute>
                        <PrivateRoute path="/messages">
                            <Messages/>
                        </PrivateRoute>
                        <PrivateRoute path="/portfolio">
                            <Portfolio/>
                        </PrivateRoute>
                        <PrivateRoute path="/tests">
                            <Tests/>
                        </PrivateRoute>
                        <PrivateRoute path="/test/:testId" hideSidebar={true}>
                            <Test/>
                        </PrivateRoute>
                        <PrivateRoute path="/docs">
                            <Documents/>
                        </PrivateRoute>
                        <PrivateRoute path="/chat/:topicId" >
                            <Chat/>
                        </PrivateRoute>
                        <PrivateRoute path="/user/:userId">
                            <StudentProfile/>
                        </PrivateRoute>
                        <PrivateRoute path="/">
                            {store.role=='teacher'?<Profile/>:<Dashboard/>}
                        </PrivateRoute>
                    </Switch>
                </Router>
            )}
        </Observer>
    </Context.Provider>
}