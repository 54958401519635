import React from "react";
import {Observer} from 'mobx-react';
import {Context} from "../utils/Context";
import {
    Link
} from "react-router-dom";
import {FileInput, PreviewImg} from "../utils/FileUpload";

import Select from 'react-select';
import AsyncSelect from 'react-select/async';


import dayjs from 'dayjs';


export default function ({children, ...rest}) {
    return <Context.Consumer>
        {(store) => (
            <Observer>
                {() => {

                    let portfolio = store.achievements.map((el) => {
                        return <div onClick={() => {
                            store.currentAchievement = el
                        }} key={"event-" + el.id} className="portfolio-item">
                            <div className="portfolio-img" style={{backgroundImage: `url(${el.preview})`}}></div>
                            <div className="portfolio-content">
                                <a href="#">{el.name}</a>
                                <div className="portfolio-date">Добавлен {el.date}</div>
                            </div>
                        </div>
                    });


                    return <div className="">
                        <h1 className="mobile-padding">Портфолио</h1>
                        <div className="mobile-padding portfolio-top-row row">
                            <a href="#" className="print" onClick={()=>{
                                 window.print();
                            }}>Печатать портфолио</a>
                            <a href="#" onClick={() => {
                                store.newAchievementError = null;
                                store.achievementErrorField = null;
                                store.newAchievementTitle ='';
                                store.newAchievementDescription = '';
                                store.newAchievementFlag = false;
                                store.newAchievementImage = false;
                                store.newAchievementType = 0;
                                store.newAchievementTypeId = 0;
                                store.newAchievementLevel = 0;
                                store.newAchievementDirection = 0;
                                store.addAchievementModalShown = true
                            }} className="btn dark">Добавить
                                награду</a>
                        </div>

                        <div className="portfolio-print">
                            {store.achievements.length?store.achievements.filter(function(el) {
                                  return el.important
                            }).length?store.achievements.filter(function(el) {
                                  return el.important
                            }).map((el)=>{
                                    return <div key={"portfolio-print-"+el.id}>
                                        <img src={el.preview} />
                                    </div>
                                })
                                :
                                <div>Не выбраны приоритетные позиции</div>
                                :
                                <div>Портфолио не заполнено</div>
                            }
                        </div>

                        <div className="portfolio-list">
                            {portfolio.length ? portfolio : <div>Вы еще не добавили достижений</div>}
                        </div>
                        {store.addAchievementModalShown ? <div className="modal">
                            <div className="modal-block">
                                <button onClick={() => store.addAchievementModalShown = false}
                                   className="link modal-cross"></button>
                                <h1>Добавить награду</h1>

                                {(store.newAchievementError) ? <ul className="row errors-block">
                                    {store.newAchievementError ? <li>{store.newAchievementError}</li> : null}
                                    {store.serverError ? <li>{store.serverError.toString()}</li> : null}
                                </ul> : null}

                                <div className="input-row">
                                    <label>Название награды:</label>
                                    <input type="text" className={"full "+(store.achievementErrorField=='title'?'error':'')}
                                           value={store.newAchievementTitle ? store.newAchievementTitle : ''}
                                           onChange={(e) => {
                                               store.newAchievementTitle = e.target.value
                                           }} placeholder="Название награды"/>
                                </div>

                                <div className="input-row">
                                    <label>Описание награды:</label>
                                    <textarea rows="4" className={"full "+(store.achievementErrorField=='description'?'error':'')} placeholder="Описание награды"
                                              onChange={(e) => {
                                                  store.newAchievementDescription = e.target.value
                                              }} value={store.newAchievementDescription ? store.newAchievementDescription : ''}/>
                                </div>
                                <div className="input-row portfolio-input-row">
                                    <div style={{flex: 1}}>
                                        <label>Изображение грамоты:</label>
                                        {store.newAchievementImage ? <PreviewImg value={store.newAchievementImage}/>
                                            : null}
                                        <div className="row">
                                            <FileInput value={store.newAchievementImage} onChange={(files) => {
                                                if (files.length) {
                                                    store.getBase64(files[0], (value, filename) => {
                                                        store.newAchievementImage = {
                                                            name: filename,
                                                            content: value.split('base64,')[1]
                                                        }
                                                    })
                                                }
                                            }}/>
                                        </div>
                                    </div>

                                    <div className="portfolio-input-checkbox">
                                        <label>
                                            Награда от ТАУ: <input type="checkbox" checked={store.newAchievementFlag}
                                                                   onChange={(e) => {
                                                                       store.newAchievementFlag = e.target.checked
                                                                   }}
                                        />
                                        </label>
                                    </div>
                                </div>
                                <div className="portfolio-input-row">
                                    <div className="input-row" style={{paddingRight: '10px'}}>
                                        <label>Направление:</label>
                                        <select className={"full "+(store.achievementErrorField=='direction'?'error':'')}
                                                onChange={(e) => {
                                                    store.newAchievementDirection = e.target.value
                                                }}>
                                            <option value="0" key={'direction-0'}>Не указано</option>
                                            {store.directions.map((el) => (
                                                <option value={el.id} key={'direction-' + el.id}>{el.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="input-row" style={{paddingRight: '10px'}}>
                                        <label>Вид:</label>
                                        <select className={"full "+(store.achievementErrorField=='type'?'error':'')}
                                                onChange={(e) => {
                                                    store.newAchievementTypeId = e.target.value
                                                }}>
                                            <option value="0" key={'type-0'}>Не указан</option>
                                            {store.achievementTypes.map((el) => (
                                                <option value={el.id} key={'type-' + el.id}>{el.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="input-row" style={{paddingRight: '10px'}}>
                                        <label>Тип:</label>
                                        <select className={"full "+(store.achievementErrorField=='type'?'error':'')}
                                                onChange={(e) => {
                                                    store.newAchievementType = e.target.value
                                                }}>
                                            <option value="0" key={'type-0'}>Не указан</option>
                                            {store.types.map((el) => (
                                                <option value={el.id} key={'type-' + el.id}>{el.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="input-row">
                                        <label>Уровень:</label>
                                        <select className={"full "+(store.achievementErrorField=='level'?'error':'')}
                                                onChange={(e) => {
                                                    store.newAchievementLevel = e.target.value
                                                }}>
                                            <option value="0" key={'level-0'}>Не указан</option>
                                            {store.levels.map((el) => (
                                                <option value={el.id} key={'level-' + el.id}>{el.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="row">
                                    <button className="btn btn-success dark full" onClick={() => {
                                        store.saveAchievement();
                                    }}>Сохранить данные
                                    </button>
                                </div>
                            </div>
                        </div> : null}

                        {store.currentAchievement ?
                            <div className="modal" onClick={() => store.closeAchievementModal()}>
                                <div className="modal-block portfolio-modal-block" onClick={(e) => e.stopPropagation()}>
                                    {!store.currentAchievement.editable ?<div className="mobile-hidden" style={{
                                        backgroundImage: store.currentAchievement.preview ? 'url(' + store.currentAchievement.preview + '?size=s)' : null,
                                        backgroundSize: 'contain',
                                        backgroundPosition: 'center',
                                        flex: 1
                                    }}></div>:
                                        <div className="mobile-hidden" style={{flex: 1}}>
                                            <label>Изображение грамоты:</label>
                                            {store.currentAchievement.preview ? <PreviewImg value={store.currentAchievement.preview}/>
                                                : null}
                                            <div className="row">
                                                <FileInput label="Обновить изображение" value={store.currentAchievement.preview} onChange={(files) => {
                                                    if (files.length) {
                                                        store.getBase64(files[0], (value, filename) => {
                                                            store.currentAchievement.preview = {
                                                                name: filename,
                                                                content: value.split('base64,')[1]
                                                            }
                                                        })
                                                    }
                                                }}/>
                                            </div>
                                        </div>
                                    }
                                    <div className="portfolio-edit-modal">
                                        <button onClick={() => store.closeAchievementModal()}
                                           className="link modal-cross" style={{alignSelf: 'flex-end'}}><div style={{height:'14px'}}></div></button>
                                        {(store.editAchievementError) ? <ul className="row errors-block">
                                            {store.editAchievementError ?
                                                <li>{store.editAchievementError}</li> : null}
                                            {store.serverError ? <li>{store.serverError.toString()}</li> : null}
                                        </ul> : null}
                                        <h1>{store.currentAchievement.editable ?
                                            <input type="text" className={"full "+(store.achievementErrorField=='title'?'error':'')}
                                                   value={store.currentAchievement.name ? store.currentAchievement.name : ''}
                                                   onChange={(e) => {
                                                       store.currentAchievement.name = e.target.value
                                                   }} placeholder="Название награды"/>
                                            :
                                            store.currentAchievement.name
                                        }</h1>
                                        <div style={{
                                            display: 'flex',
                                            flex: '1',
                                            flexDirection: 'column'
                                        }}>

                                            {!store.currentAchievement.editable ? <div className="input-row">
                                                <span className="portfolio-date">Добавлен {store.currentAchievement.date}</span>
                                            </div> : null}

                                            <div className="input-row">
                                                {store.currentAchievement.editable ?
                                                    <textarea rows="4" className={"full "+(store.achievementErrorField=='description'?'error':'')} placeholder="Описание награды"
                                                              onChange={(e) => {
                                                                  store.currentAchievement.description = e.target.value
                                                              }} value={store.currentAchievement.description ? store.currentAchievement.description : ''}/> :
                                                    <div>{store.currentAchievement.description}</div>
                                                }

                                            </div>

                                            {store.currentAchievement.editable ?
                                                <div>

                                                    <div className="input-row" style={{paddingRight: '10px'}}>
                                                        <label>Вид:</label>
                                                        <select className={"full "+(store.achievementErrorField=='achievement_type'?'error':'')}
                                                                onChange={(e) => {
                                                                    store.currentAchievement.achievement_type_id = e.target.value
                                                                }}>
                                                            {store.achievementTypes.map((el) => (
                                                                <option value={el.id}
                                                                        selected={store.currentAchievement.achievement_type_id == el.id}
                                                                        key={'edit-achievement-type-' + el.id}>{el.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>

                                                    <div className="input-row" style={{paddingRight: '10px'}}>
                                                        <label>Направление:</label>
                                                        <select className={"full "+(store.achievementErrorField=='direction'?'error':'')}
                                                                onChange={(e) => {
                                                                    store.currentAchievement.direction_id = e.target.value
                                                                }}>
                                                            {store.directions.map((el) => (
                                                                <option value={el.id}
                                                                        selected={store.currentAchievement.direction_id == el.id}
                                                                        key={'edit-direction-' + el.id}>{el.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="input-row" style={{paddingRight: '10px'}}>
                                                        <label>Тип:</label>
                                                        <select className={"full "+(store.achievementErrorField=='type'?'error':'')}
                                                                onChange={(e) => {
                                                                    store.currentAchievement.type_id = e.target.value
                                                                }}>
                                                            {store.types.map((el) => (
                                                                <option value={el.id}
                                                                        selected={store.currentAchievement.type_id == el.id}
                                                                        key={'edit-type-' + el.id}>{el.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="input-row">
                                                        <label>Уровень:</label>
                                                        <select className={"full "+(store.achievementErrorField=='level'?'error':'')}
                                                                onChange={(e) => {
                                                                    store.currentAchievement.level_id = e.target.value
                                                                }}>
                                                            {store.levels.map((el) => (
                                                                <option value={el.id}
                                                                        selected={store.currentAchievement.level_id == el.id}
                                                                        key={'edit-level-' + el.id}>{el.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div> :
                                                <div>
                                                    {store.getById('directions', store.currentAchievement.direction_id)?<div className="btn light border" style={{cursor:'default',maxWidth:'150px', marginBottom:'20px'}}>{store.getById('directions', store.currentAchievement.direction_id).name}</div>:null}
                                                    <div>Вид: {store.getById('achievementTypes', store.currentAchievement.achievement_type_id).name}</div>
                                                    <div>Тип: {store.getById('types', store.currentAchievement.type_id).name}</div>
                                                    <div>Уровень: {store.getById('levels', store.currentAchievement.level_id).name}</div>
                                                </div>
                                            }

                                    {!store.currentAchievement.editable ?<div className="desktop-hidden" style={{
                                        backgroundImage: store.currentAchievement.preview ? 'url(' + store.currentAchievement.preview + '?size=s)' : null,
                                        backgroundSize: 'contain',
                                        backgroundPosition: 'center',
                                        flex: 1
                                    }}></div>:
                                        <div className="desktop-hidden" style={{flex: 1}}>
                                            <label>Изображение грамоты:</label>
                                            {store.currentAchievement.preview ? <PreviewImg value={store.currentAchievement.preview}/>
                                                : null}
                                            <div className="row">
                                                <FileInput label="Обновить изображение" value={store.currentAchievement.preview} onChange={(files) => {
                                                    if (files.length) {
                                                        store.getBase64(files[0], (value, filename) => {
                                                            store.currentAchievement.preview = {
                                                                name: filename,
                                                                content: value.split('base64,')[1]
                                                            }
                                                        })
                                                    }
                                                }}/>
                                            </div>
                                        </div>
                                    }
                                            <div className="row" style={{
                                                justifyContent: 'center',
                                                marginBottom: '20px'
                                            }}>
                                                {store.currentAchievement.editable ?
                                                    <button className="btn btn-success dark full" style={{
                                                        width: '100%'
                                                    }} onClick={() => {
                                                        store.editAchievement();
                                                    }}>Сохранить</button> :
                                                    <a className="btn btn-success dark full" style={{
                                                        width: '100%'
                                                    }} target="_blank" href={store.currentAchievement.preview}>Скачать</a>}
                                            </div>
                                            <div className="row" style={{
                                                justifyContent: 'space-between'
                                            }}>
                                                {(!store.currentAchievement.important && store.importantAchievementsAllowed) ?
                                                    <a href="#"  onClick={() => {
                                                        store.setAchievementImportantStatus(true);
                                                    }} >Сделать приоритетной</a> : (store.currentAchievement.important ?
                                                        <a href="#" onClick={() => {
                                                            store.setAchievementImportantStatus(false);
                                                        }}>Убрать приоритет</a> : null)}

                                                {store.currentAchievement.editable ? null :
                                                    <button className="link" onClick={() => store.currentAchievement.editable = true}>Редактировать</button>}
                                                <button className="link" onClick={() => {
                                                    store.removeAchievement();
                                                }}>Удалить</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div> : null}
                    </div>
                }}
            </Observer>
        )}
    </Context.Consumer>;
}