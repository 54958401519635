import React from "react";
import {Observer} from 'mobx-react';
import {Context} from "../utils/Context";
import {
    Link
} from "react-router-dom";

import Select from 'react-select';
import AsyncSelect from 'react-select/async';

import {confirmAlert} from 'react-confirm-alert';

import {FileInput} from "../utils/FileUpload";

import dayjs from 'dayjs';
import history from "../utils/History";

export default function ({children, ...rest}) {
    return <Context.Consumer>
        {(store) => (
            <Observer>
                {() => {
                    let parts = window.location.pathname.split('/');
                    let eventId = parts[parts.length - 1];
                    if (eventId && store.currentEventToRequest != eventId) {
                        store.currentEventToRequest = eventId;
                        store.events.forEach((event) => {
                            if (event.id == eventId) {
                                if (event.req_attach) {
                                    store.requestAttachment = null;
                                    store.requestAttachmentError = false;
                                    store.currentRequestEvent = event.id;
                                    store.requestAttachmentModal = true;
                                } else {
                                    store.requestEvent(event.id);
                                }
                            }
                        });
                    }
                    let events = store.events.filter(function (el) {
                        if (store.role == 'student' && el.event_status == 1) {
                            return false;
                        }
                        if (store.role == 'student' && store.eventsType == 'requested' && el.requested) {
                            return true;
                        }
                        if (store.role == 'teacher' && store.eventsType == 'requested' && el.author == store.user.id) {
                            return true;
                        }
                        if (store.eventsType == 'featured' && !el.expire) {
                            return true;
                        }
                        if (store.eventsType == 'old' && el.expire) {
                            return true;
                        }
                        return false;
                    }).map((event) => {
                            return <div key={"event-" + event.id} className="events-item">
                                <div className="events-item-img">{event.skin ? <img src={event.skin} style={{
                                    height: 'auto'
                                }}/> : null}</div>
                                <div className="events-item-content">
                                    {store.role == 'teacher' && event.author == store.user.id ?
                                        <Link style={{marginTop: 0}} to={"/events/" + event.id}
                                              className="events-item-action red"><h4>{event.short_name}</h4></Link>
                                        : <h4>{event.short_name}</h4>}
                                    <div className="events-item-row">
                                        <div className="events-item-date">{event.start.substr(0, 10)}</div>
                                        <div className="events-item-status">

                                            {store.role == 'student' ? (dayjs(event.regEnd) < dayjs())?<div></div>:((store.eventsType == 'requested' || (store.eventsType == 'featured' && event.requestId)) ?
                                                <span>Статус: {event.requestStatus == 'accepted' ?
                                                    <span className="green">Заявка одобрена</span> :
                                                    event.requestStatus == 'rejected' ?
                                                        <span className="red">Заявка отклонена</span> :
                                                        <span>Заявка на рассмотрении</span>
                                                }</span>
                                                : (store.eventsType == 'featured' && !event.expire ?

                                                    event.req_attach ?
                                                        <button className="btn border" onClick={() => {
                                                            store.requestAttachment = null;
                                                            store.requestAttachmentError = false;
                                                            store.currentRequestEvent = event.id;
                                                            store.requestAttachmentModal = true;
                                                        }}>Подать заявку на участие</button> :
                                                        <button className="btn border" onClick={() => {
                                                            store.requestEvent(event.id)
                                                        }}>Подать заявку на участие</button>
                                                    : null)) : null}
                                        </div>
                                    </div>
                                    <p dangerouslySetInnerHTML={{__html: event.description}}></p>

                                    {store.role == 'student' ? event.requestId ?
                                        <a href="#" onClick={() => store.rejectRequest(event.requestId)}
                                           className="events-item-action red">Отказаться от участия</a> : null
                                        : null}

                                    {store.role == 'teacher' && event.author == store.user.id ?
                                        <Link to={"/events/" + event.id} className="events-item-action red">Просмотр</Link>
                                        : null}

                                    {store.role == 'student' || event.author != store.user.id ?
                                        <a href={"https://tau18.ru/events/" + event.id + "/"} target="_blank"
                                           className="events-item-action red">Подробнее</a>
                                        : null}
                                </div>
                            </div>
                        }
                    );

                    return <div>
                        <h1 className="mobile-padding">Мероприятия</h1>
                        <div className="mobile-padding events-menu">
                            <div>
                                <a href="#" onClick={() => {
                                    store.eventsType = 'requested'
                                }} className={store.eventsType == 'requested' ? 'active' : null}>Мои мероприятия</a>
                                <a href="#" onClick={() => {
                                    store.eventsType = 'featured'
                                }} className={store.eventsType == 'featured' ? 'active' : null}>Рекомендуемые
                                    мероприятия</a>
                                <a href="#" onClick={() => {
                                    store.eventsType = 'old'
                                }} className={store.eventsType == 'old' ? 'active' : null}>Прошедшие мероприятия</a>
                            </div>
                            {store.role == 'teacher' ? <div>
                                <Link to="/events/new" onClick={() => {
                                    store.resetEvent()
                                }} className="btn dark">Добавить мероприятие</Link>
                            </div> : null}
                        </div>
                        <div className="events-list">
                            {events.length ? events : <div>Мероприятий не найдено</div>}
                        </div>

                        {store.requestAttachmentModal ? <div id="react-confirm-alert">
                            <div className="react-confirm-alert-overlay">
                                <div className="react-confirm-alert">
                                    <div className="react-confirm-alert-body"><h1>Подать заявку на участие</h1>
                                        <div>
                                            <p>Для отправки заявки на участие в мероприятии необходимо приложить
                                                файл</p>
                                            <div>
                                                {store.requestAttachmentError ? <div
                                                    style={{color: 'red'}}>{store.requestAttachmentError}</div> : null}
                                                {store.requestAttachment ?
                                                    <div>Файл приложен: {store.requestAttachment.name}
                                                        <button style={{marginLeft: '20px'}} onClick={() => {
                                                            store.requestAttachment = null
                                                        }}>Удалить
                                                        </button>
                                                    </div> : null}
                                                <FileInput label="Приложить файл" onChange={(files) => {
                                                    console.log('Files', files)
                                                    if (files.length) {
                                                        store.getBase64(files[0], (value, filename) => {
                                                            store.requestAttachment = {
                                                                content: value.split('base64,')[1],
                                                                name: filename
                                                            }
                                                            console.log(store.requestAttachment)
                                                        })
                                                    }
                                                }}/>
                                                <div className="row">
                                                    <div className="input-row">
                                                        <label>Комментарий:</label>
                                                        <textarea style={{width: '100%'}}
                                                                  value={store.requestAttachmentDescription ? store.requestAttachmentDescription : ''}
                                                                  onChange={(e) => {
                                                                      store.requestAttachmentDescription = e.target.value
                                                                  }}></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="react-confirm-alert-button-group">
                                            <button onClick={() => {
                                                if (!store.requestAttachment) {
                                                    store.requestAttachmentError = 'Необходимо приложить файл';
                                                } else {
                                                    store.apiRequest('/request', {
                                                        "Request": {
                                                            "event_id": store.currentRequestEvent,
                                                            "file": store.requestAttachment,
                                                            "description": store.requestAttachmentDescription
                                                        }
                                                    }, (data) => {
                                                        store.requestAttachmentModal = false;
                                                        store.showConfirmation('Заявка отправлена');
                                                    }, function () {
                                                        store.requestAttachmentModal = false;
                                                        store.showConfirmation('Заявку отправить не удалось');
                                                    }, 'post');
                                                }
                                            }}>Отправить
                                            </button>
                                            <button onClick={() => store.requestAttachmentModal = false}>Отмена</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : null}
                    </div>
                }}
            </Observer>
        )}
    </Context.Consumer>;
}