import React from "react";
import {Observer} from 'mobx-react';
import {Context} from "../utils/Context";
import {
    Link
} from "react-router-dom";

import Select from 'react-select';
import AsyncSelect from 'react-select/async';
export default function ({children, ...rest}) {
    return <Context.Consumer>
        {(store) => (
            <Observer>
                {() => {

                    let topics = [];
                    for (let [topicId, messages] of Object.entries(store.messages)) {
                      if (messages.length){
                          let topic = messages[0];
                          let author = topic.author;
                          if (topic.author_id == store.user.id){
                              author = topic.recipient;
                          }
                          topics.push(<Link to={"/chat/"+topicId} onClick={()=>{
                              store.topicsSeen[topicId] = 0;
                              store.markMessagesAsRead(messages)
                          }} key={'topic-'+topicId} className="messages-list-item">
                                <div className="messages-list-item-avatar">{author[0]}</div>
                                <div className="messages-list-item-msg">
                                    <h4>{author}</h4>
                                    <p>{topic.message}</p>
                                </div>

                                <div className="messages-list-item-date">
                                    <div>{topic.date}</div>
                                    {store.topicsSeen[topicId]?<div className="badge">{store.topicsSeen[topicId]}</div>:null}
                                </div>
                            </Link>)
                      }
                    }

                    return <div>
                        <div className="mobile-padding messages-header">
                            <h1>Мои сообщения</h1>
                            <Link to={"/chat/0"} className="moderator">Написать модератору</Link>
                        </div>
                        <div className="messages-list">
                            {topics.length?topics:<div>Нет сообщений</div>}
                        </div>
                    </div>
                }}
            </Observer>
        )}
    </Context.Consumer>;
}